import React from 'react';
import { DisclaimerWrapper } from './Disclaimer.styled';
import {
  Colors,
  EFontWeight,
  TextSizes,
  VoxText,
} from '@vox-capital/vox-ui-package';

const Disclaimer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // State manager
  const [disclaimer, setDisclaimer] = React.useState<boolean>(false);
  // Component
  return (
    <DisclaimerWrapper
      onMouseEnter={() => setDisclaimer(true)}
      onMouseLeave={() => setDisclaimer(false)}
    >
      <VoxText
        preset={TextSizes.bodySm}
        weight={EFontWeight.bold}
        color={Colors.light}
      >
        i
      </VoxText>
      {/* <span>i</span> */}
      {children && disclaimer && <div className='description'>{children}</div>}
    </DisclaimerWrapper>
  );
};

export default Disclaimer;
