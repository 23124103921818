// Component generated with util/vox-create-component.js
import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import { AppHomeWrapper, EmptyData, AppHomeGraph } from './AppHome.styled';
import uniqid from 'uniqid';
import { AppHomeProps } from './AppHome.types';
import { UI, AppHistoryData } from './../../component';
import { graphTags } from './../../constants';
import { useTranslation } from 'react-i18next';
import {
  useGetPortafolioLazyQuery,
  useGetVoxDocumentsLazyQuery,
  Position,
  Impact,
  Kaleidoscope,
} from './../../graphql/types.d';
import {
  transFormCurreny,
  formatGraphData,
  transFormNumber,
  formatImpactData,
  formatDocumentsData,
} from './../../helpers';
import { AuthContext } from './../../context/AuthContext';
import { AlertContext } from './../../context/AlertContext';
import { AppContext } from './../../context/AppContext';
import { SensitiveContext } from './../../context/SensitiveContext';
// import { useTheme } from 'styled-components';
import {
  Loader,
  // Loading,
  Disclaimer,
  Loading,
} from './../../ui';
import { downLoadDocumentFile } from './../../service/api';
import { useHistory } from 'react-router-dom';
import {
  EAlertType,
  EButtonPreset,
  Icons,
  VoxLayoutContainer,
  Colors,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import {
  dataDogTags,
  trackAction,
  trackError,
  trackView,
} from '../../analytics';

const impactDataPerPage = 6;

export const AppHome: React.FC<AppHomeProps> = ({ ...props }) => {
  trackView(dataDogTags.views.privateHome, dataDogTags.views.privateHome);
  // Navigation
  const history = useHistory();
  // Refs
  const refHome = React.useRef<HTMLDivElement>(null);
  const refGraphContainer = React.useRef<HTMLDivElement>(null);
  // Theme
  // const theme = useTheme();
  // graphql hooks
  const [getPortfolio, { data, error, loading, refetch }] =
    useGetPortafolioLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const [getVoxDocumentsData, { data: voxData, loading: voxLoading }] =
    useGetVoxDocumentsLazyQuery({
      notifyOnNetworkStatusChange: false,
    });
  // Translations
  const [t] = useTranslation('home');
  const [d] = useTranslation('documents');
  const [s] = useTranslation('statements');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // Context
  const { dispatch } = React.useContext(AppContext);
  const { user, setCurrentPosition, clearSession, currentPosition } =
    React.useContext(AuthContext);
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  const { state } = React.useContext(SensitiveContext);
  // States
  const [downloading, setDownloading] = React.useState(false);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [documents, setDocuments] = React.useState<any[]>([]);
  const [profitability, setProfitability] = React.useState<any>(0);
  const [lastPositionDate, setLastPositionDate] = React.useState<any>(
    new Date()
  );
  const [historyData, setHistoryData] = React.useState<any[]>([]);
  // const [historyIpact, setHistoryImpact] = React.useState<any[]>([]);
  const [histories, setHistories] = React.useState<any[]>([]);
  const [profitabilityValue, setProfitabilityValue] = React.useState<number>(0);
  // Effects
  // Star calling porfolio data
  React.useEffect(() => {
    if (R.isEmpty(positions)) {
      getVoxDocumentsData({
        variables: {
          path: 'common',
        },
      });
      getPortfolio({
        variables: {
          personId: `${user?.personId}`,
        },
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPortfolio, getVoxDocumentsData]);
  // When get porfolio data
  React.useEffect(() => {
    if (error) {
      const gqlError = error.graphQLErrors[0];
      const errorCode = gqlError ? gqlError.extensions.code : true;
      if (errorCode && errorCode.toString() === 'UNAUTHENTICATED') {
        showAlert({
          title: e('errors.auth.SessionExpired.title'),
          message: e('errors.auth.SessionExpired.message'),
          type: EAlertType.error,
          primaryAlertAction: () => {
            clearSession();
            closeAlert();
          },
        });
      } else {
        showAlert({
          title: c('errors.common.wrong.title'),
          message: c('errors.common.wrong.message'),
          type: EAlertType.error,
        });
      }
      return;
    } else if (data && data.portfolio && data.portfolio.financialSummary) {
      const dataPosition: any[] = data.portfolio.positions || [];
      const dataDocuments: any[] = voxData?.documents || [];
      const formatData: any[] =
        formatGraphData(data.portfolio.financialSummary.historyData) || [];
      const cPosition =
        data.portfolio.financialSummary.historyData?.position || [];
      const cDates = data.portfolio.financialSummary.historyData?.date || [];
      const cImpact: Impact[] = formatImpactData(dataPosition) || [];
      const cDocuments: any[] =
        formatDocumentsData(dataPosition, dataDocuments) || [];
      dispatch({
        type: 'SET_POSITIONS_DATA',
        payload: {
          allPositions: data.portfolio.positions,
        },
      });
      dispatch({
        type: 'SET_DOCUMENTS_DATA',
        payload: {
          allDocuments: dataDocuments || [],
        },
      });
      // TODO: Add documents to list
      setDocuments([...cDocuments]);
      setHistories(cImpact);
      setProfitabilityValue(
        data.portfolio.financialSummary.totalProfitability || 0
      );
      setProfitability(data.portfolio.financialSummary.profitability);
      setLastPositionDate(
        moment(cDates[cDates.length - 1] || new Date()).format('yyyy-MM-DD')
      );
      setHistoryData(formatData);
      console.log('dataPosition: ', dataPosition);
      setPositions(dataPosition.filter(pos => pos.fund));
      setCurrentPosition({
        grossValue: transFormCurreny(cPosition[cPosition.length - 1] || 0),
        profitability: `${transFormCurreny(
          data.portfolio.financialSummary.totalProfitability || 0
        )}`,
        totalProfitability: `${transFormNumber(
          data.portfolio.financialSummary.profitability || 0.0
        )}%`,
        positive: (data.portfolio.financialSummary.profitability || 0) >= 0,
      });
    }
    return () => {
      setPositions([]);
      setHistoryData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  // Handlers
  const downloadDocument = async (file: string) => {
    if (!file) return;
    setDownloading(true);
    trackAction(dataDogTags.actions.home.portfolio.document, { file });
    const response = await downLoadDocumentFile(file);
    if (response && response.error) {
      trackError(dataDogTags.fetch.errors.printfile, { response });
      showAlert({
        type: EAlertType.error,
        title: d('documents.error.download.title'),
        message: d('documents.error.download.gettingUrl'),
      });
    }
    setDownloading(false);
  };
  // Components
  if (loading || voxLoading) {
    return (
      <AppHomeWrapper ref={refHome}>
        <EmptyData data-testid='vox-data-loading'>
          <Loader message='Carregando' />
        </EmptyData>
      </AppHomeWrapper>
    );
  }
  if (!data || !data.portfolio) {
    return (
      <AppHomeWrapper ref={refHome}>
        <EmptyData data-testid='vox-graph-empty'>
          <UI.VoxText preset={TextSizes.body} color={Colors.gray800}>
            {s('graph.errors.empty.title')}
          </UI.VoxText>
          <UI.VoxText>{s('graph.errors.empty.message')}</UI.VoxText>
          <br />
          <UI.VoxText
            underline
            color={Colors.pink800}
            preset={TextSizes.body}
            onClick={() =>
              refetch
                ? refetch({
                    personId: `${user?.personId}`,
                  })
                : {}
            }
          >
            {c('empty.funds.action.label')}
          </UI.VoxText>
        </EmptyData>
      </AppHomeWrapper>
    );
  }
  return (
    <div className='flex flex-col gap-16 w-full items-center' ref={refHome}>
      {downloading && <Loading loadingMessage='Aguarde um momento' />}
      <div className='bg-gray-200 w-full flex items-center justify-center'>
        <VoxLayoutContainer>
          <div className='financial-data flex flex-col gap-2 justify-start items-start py-16'>
            <div className='flex flex-col md:flex-row-reverse w-full gap-2 flex-1 bg-gray-400 rounded-md items-center'>
              <div
                className='w-full md:w-8/12 h-256 overflow-hidden rounded-r-md'
                style={{
                  backgroundPosition: 'top',
                  backgroundSize: 'cover',
                  backgroundImage:
                    'url("https://frontend-assets-voxri.s3.sa-east-1.amazonaws.com/report/vox-image-og-banner-card.png")',
                }}
              />
              <div className='flex flex-col w-full md:w-4/12 pl-8 pr-4 pt-8 pb-4 gap-4'>
                <div className='pb-2 flex flex-col'>
                  <UI.VoxText
                    preset={TextSizes.bodyMd}
                    weight={EFontWeight.bold}
                    color={Colors.pink800}
                  >
                    Rentabilidade
                  </UI.VoxText>
                  <UI.VoxText
                    preset={TextSizes.heading4}
                    weight={EFontWeight.bold}
                  >
                    {t('overview.impact.profitability')}
                    <UI.VoxText
                      preset={TextSizes.heading4}
                      weight={EFontWeight.bold}
                      color={profitability ? Colors.dark200 : Colors.light}
                    >
                      {' '}
                      {state?.showSensitiveValues
                        ? currentPosition.totalProfitability
                        : '**,**%'}
                    </UI.VoxText>
                  </UI.VoxText>
                </div>
                <div className='flex gap-2 items-center justify-end cursor-pointer'>
                  <Disclaimer
                    children={
                      <div className='py-4'>
                        <UI.VoxText
                          preset={TextSizes.bodyMd}
                          weight={EFontWeight.medium}
                          color={Colors.darkBlue}
                        >
                          <b>Rentabilidade:</b> A cota do fundo é impactada
                          negativamente quando há evento de amortização de
                          cotas. Dessa forma, nos casos em que houve
                          amortização, a rentabilidade demonstrada neste
                          relatório é gerencial, uma vez que ela considera os
                          ganhos auferidos com as amortizações já pagas.
                        </UI.VoxText>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </VoxLayoutContainer>
      </div>
      <VoxLayoutContainer>
        <div className='flex flex-col w-full gap-16' ref={refGraphContainer}>
          {refGraphContainer.current &&
            !R.isEmpty(historyData) &&
            data &&
            data.portfolio && (
              <div className='w-full'>
                <AppHomeGraph data-testid='vox-graph-data'>
                  <UI.VoxGraph
                    width={refGraphContainer.current.clientWidth}
                    height={420}
                    cash={
                      state?.showSensitiveValues
                        ? currentPosition.grossValue || `R$ 0,00`
                        : '******,**'
                    }
                    profitability={
                      state?.showSensitiveValues
                        ? `${transFormCurreny(profitabilityValue)}`
                        : '******,**'
                    }
                    date={`${moment(lastPositionDate).format('LL')}`}
                    positive={profitabilityValue > 0}
                    tags={graphTags}
                    graphData={historyData}
                  />
                </AppHomeGraph>
              </div>
            )}
          {positions && !R.isEmpty(positions) ? (
            <div className='statements flex flex-col md:flex-row md:gap-12'>
              <div className='title flex flex-col gap-4 w-full md:w-4/12'>
                <UI.VoxText
                  preset={TextSizes.heading4}
                  weight={EFontWeight.bold}
                  color={Colors.darkBlue}
                >
                  Distribuição da minha carteira
                </UI.VoxText>
                <UI.VoxText
                  preset={TextSizes.bodyLg}
                  weight={EFontWeight.bold}
                  color={Colors.gray500}
                >
                  Confira como o seu patrimônio está distribuído na VOX Capital
                  e otimize suas oportunidades de crescimento.
                </UI.VoxText>
              </div>
              <div
                className='items flex flex-col md:grid lg:grid-cols-2 gap-4 items-center align-middle justify-between py-8 w-full md:w-8/12'
                data-testid='vox-position-list'
              >
                {positions.map((pos: Position) => (
                  <div
                    className='position bg-gray-100 border-2 border-gray-200 rounded-md flex flex-col gap-4 w-full'
                    key={uniqid()}
                  >
                    <div className='fund md:h-128 pt-8 pb-2 px-6 bg-gray-200 flex items-end'>
                      <UI.VoxText
                        preset={TextSizes.bodyMd}
                        color={Colors.pink800}
                        weight={EFontWeight.bold}
                      >
                        {pos.fund?.name || 'Fundo vox'}
                      </UI.VoxText>
                    </div>
                    <div className='flex flex-col gap-4 px-6 pb-4'>
                      <div className='data flex flex-col gap-1'>
                        <UI.VoxText
                          preset={TextSizes.bodyMd}
                          color={Colors.gray500}
                          weight={EFontWeight.medium}
                        >
                          Patrimônio no fundo
                        </UI.VoxText>
                        <div className='flex gap-2 items-center'>
                          <UI.VoxText
                            preset={TextSizes.bodyLg}
                            color={Colors.darkBlue}
                            weight={EFontWeight.bold}
                          >
                            {state?.showSensitiveValues
                              ? `${transFormCurreny(
                                  pos.currentPositionValue || 0
                                )}`
                              : '******,**'}
                          </UI.VoxText>
                          <Disclaimer
                            children={
                              <div className='py-4 flex flex-col gap-2'>
                                <UI.VoxText
                                  preset={TextSizes.bodyMd}
                                  weight={EFontWeight.medium}
                                  color={Colors.darkBlue}
                                >
                                  <b>Patrimônio:</b> Patrimônio bruto calculado
                                  a partir da cota do fundo na data expressa
                                  acima, considerando a valorização das cotas de
                                  acordo com o fim do exercício social do fundo
                                </UI.VoxText>
                                <span className='text-gray-500 text-sm'>
                                  obs: cálculo realizado levando em consideração
                                  apenas o valor já integralizado
                                </span>
                              </div>
                            }
                          />
                        </div>
                      </div>
                      {pos.fund?.totalProfitability !== null && (
                        <div className='data flex flex-col'>
                          <UI.VoxText
                            preset={TextSizes.bodyMd}
                            color={Colors.gray500}
                            weight={EFontWeight.medium}
                          >
                            Rentabilidade do fundo
                          </UI.VoxText>
                          <div className='flex gap-2 items-center'>
                            <UI.VoxText
                              className='value'
                              preset={TextSizes.bodyLg}
                              color={Colors.darkBlue}
                              weight={EFontWeight.bold}
                            >
                              {state?.showSensitiveValues
                                ? `${transFormCurreny(pos.totalProfitability)}`
                                : '******,**'}
                            </UI.VoxText>
                            <Disclaimer
                              children={
                                <div className='py-4'>
                                  <UI.VoxText
                                    preset={TextSizes.bodyMd}
                                    weight={EFontWeight.medium}
                                    color={Colors.darkBlue}
                                  >
                                    <b>Rentabilidade:</b> A cota do fundo é
                                    impactada negativamente quando há evento de
                                    amortização de cotas. Dessa forma, nos casos
                                    em que houve amortização, a rentabilidade
                                    demonstrada neste relatório é gerencial, uma
                                    vez que ela considera os ganhos auferidos
                                    com as amortizações já pagas.
                                  </UI.VoxText>
                                </div>
                              }
                            />
                          </div>
                          <UI.VoxText
                            className='value'
                            preset={TextSizes.bodyLg}
                            color={Colors.darkBlue}
                            weight={EFontWeight.bold}
                          >
                            {state?.showSensitiveValues
                              ? `${transFormNumber(pos.profitability || 0)}%`
                              : '******,**'}
                          </UI.VoxText>
                        </div>
                      )}
                      {pos.subscriptionValue !== null && (
                        <div className='flex flex-col'>
                          <UI.VoxText
                            preset={TextSizes.bodyMd}
                            color={Colors.gray500}
                            weight={EFontWeight.medium}
                          >
                            Valor Subscrito
                          </UI.VoxText>
                          <div className='flex gap-2 items-center'>
                            <UI.VoxText
                              className='value'
                              preset={TextSizes.bodyLg}
                              color={Colors.darkBlue}
                              weight={EFontWeight.bold}
                            >
                              {state?.showSensitiveValues
                                ? `${transFormCurreny(pos.subscriptionValue)}`
                                : '******,**'}
                            </UI.VoxText>
                            <Disclaimer
                              children={
                                <div className='py-4 flex flex-col gap-2'>
                                  <UI.VoxText
                                    preset={TextSizes.bodyMd}
                                    weight={EFontWeight.medium}
                                    color={Colors.darkBlue}
                                  >
                                    <b>Valor Subscrito:</b> Valor comprometido
                                    de investimento ao fundo
                                  </UI.VoxText>
                                  <UI.VoxText
                                    preset={TextSizes.bodyMd}
                                    weight={EFontWeight.medium}
                                    color={Colors.darkBlue}
                                  >
                                    <b>Valor Integralizado:</b> Valor já
                                    integralizado através das chamadas de
                                    capital
                                  </UI.VoxText>
                                </div>
                              }
                            />
                          </div>
                          <UI.VoxText
                            preset={TextSizes.bodyMd}
                            color={Colors.gray500}
                            weight={EFontWeight.medium}
                          >
                            {(() => {
                              if (!state?.showSensitiveValues) {
                                return '******,**';
                              }
                              const percent = (
                                (100 * (pos.integralizationValue || 0)) /
                                (pos.subscriptionValue || 1)
                              ).toFixed(0);
                              if (!percent) {
                                return '-';
                              }
                              return `${percent}% `;
                            })()}
                            integralizado
                          </UI.VoxText>
                          <UI.VoxText
                            preset={TextSizes.bodyMd}
                            color={Colors.gray500}
                            weight={EFontWeight.medium}
                          >
                            {transFormCurreny(pos.integralizationValue)}
                          </UI.VoxText>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div data-testid='vox-positions-empty'>
              <EmptyData>
                <UI.VoxText preset={TextSizes.body} color={Colors.gray800}>
                  {c('empty.funds.label')}
                </UI.VoxText>
                <UI.VoxText>{c('empty.funds.description')}</UI.VoxText>
                <br />
                <UI.VoxText
                  underline
                  color={Colors.pink800}
                  preset={TextSizes.body}
                  onClick={() =>
                    refetch
                      ? refetch({
                          personId: `${user?.personId}`,
                        })
                      : {}
                  }
                >
                  {c('empty.funds.action.label')}
                </UI.VoxText>
              </EmptyData>
            </div>
          )}
        </div>
      </VoxLayoutContainer>
      {histories && !R.isEmpty(histories) && (
        <div className='flex flex-col w-full items-center border-t-2 border-gray-200 pt-24'>
          <VoxLayoutContainer>
            <div
              className='impact flex flex-col md:flex-row md:gap-12'
              data-testid='vox-history-impact'
            >
              <div className='header flex flex-col gap-4 w-full md:w-4/12'>
                <UI.VoxText
                  preset={TextSizes.heading4}
                  weight={EFontWeight.bold}
                >
                  Nossas investidas são quem faz o impacto acontecer
                </UI.VoxText>
                <UI.VoxText
                  preset={TextSizes.bodyLg}
                  weight={EFontWeight.bold}
                  color={Colors.gray500}
                >
                  Conheça evidências de como seus investimentos estão
                  colaborando para um mundo 100% regenerado para as pessoas,
                  cidades e planeta.
                </UI.VoxText>
              </div>
              <div
                className='items flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 items-center align-middle justify-between py-8 w-full md:w-8/12'
                data-testid='vox-position-list'
              >
                {R.dropLast(
                  histories.length - impactDataPerPage,
                  histories
                ).map((i: Kaleidoscope) => (
                  <AppHistoryData key={i.id} history={i} />
                ))}
              </div>
            </div>
          </VoxLayoutContainer>
        </div>
      )}
      <div className='bg-gray-100 w-full pt-16 pb-10 flex items-center justify-center'>
        <VoxLayoutContainer>
          {documents && !R.isEmpty(documents) && (
            <div
              className='portfolio flex flex-col md:flex-row md:gap-8'
              data-testid='vox-documents'
            >
              <div className='description flex flex-col gap-4 w-full md:w-4/12'>
                <UI.VoxText
                  preset={TextSizes.heading4}
                  weight={EFontWeight.bold}
                >
                  Atualizações VOX
                </UI.VoxText>
                <UI.VoxText
                  preset={TextSizes.bodyLg}
                  weight={EFontWeight.bold}
                  color={Colors.gray500}
                >
                  Aqui você encontra relatórios que explicam o que está
                  acontecendo no seu portfólio.
                </UI.VoxText>
              </div>
              <div className='list py-8 w-full md:w-8/12 flex flex-col items-end gap-4'>
                <div className='documents flex flex-col gap-2 w-full'>
                  {documents.map((doc: any) => (
                    <UI.VoxDocumentItem
                      key={uniqid()}
                      label={doc.fund}
                      description={doc.type}
                      icon={Icons.system_update_alt}
                      onClick={() => {
                        downloadDocument(doc.id);
                      }}
                      data-vox-action={
                        dataDogTags.actions.home.portfolio.showMore
                      }
                    />
                  ))}
                </div>
                <div className='footer'>
                  <UI.VoxButton
                    label='Ver mais'
                    rightAddon={Icons.help}
                    color={Colors.pink800}
                    preset={EButtonPreset.link}
                    data-vox-action={
                      dataDogTags.actions.home.portfolio.showMore
                    }
                    onClick={() => history.push('/AppDocuments')}
                  />
                </div>
              </div>
            </div>
          )}
        </VoxLayoutContainer>
      </div>
    </div>
  );
};

export default AppHome;
