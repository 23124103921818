// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppProfileWrapper = styled.div``;
export const AppProfileActionsWrapper = styled.div`
  margin-top: 12px;
`;
