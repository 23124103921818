// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppExtractsWrapper = styled.div`
  .spinner {
    display: none;
    background-color: ${({ theme }) => theme.colors.gray100};
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
  }
  @media (max-width: 720px) {
    .loading {
      > .skeleton {
        display: none;
      }
      > .spinner {
        display: flex;
      }
    }
  }
`;
export const PageTitle = styled.div`
  width: 100%;
`;
export const AppExtractsHeader = styled.div`
  width: 100%;
`;
export const AppExtractsBody = styled.div`
  & .pagination {
    width: calc(100% - 32px);
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    & > span {
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.gray400};
    }
  }
`;
export const AppExtractsList = styled.div<{ disabled?: boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  & .loading {
    position: absolute;
    width: 100vw;
    top: 0;
    bottom: 0;
    z-index: 99;
    cursor: not-allowed;
  }
  & .showMore {
    width: 52px;
    transform: rotate(180deg);
  }
  & > .card-container {
    transition-property: transform;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    align-items: center;
    & .card {
      & .vox-card {
        min-width: 100%;
        border-radius: 8px;
        margin-bottom: 16px;
      }
    }
    @media (min-width: 740px) {
      display: flexbox;
      gap: 16px;
      & .card {
        width: calc(33.33333% - 16px);
        & .vox-card {
          margin-bottom: 0;
        }
      }
    }
    @media (min-width: 1040px) {
      & .card {
        width: calc(25% - 16px);
      }
    }
    @media (min-width: 1444px) {
      & .card {
        width: calc(20% - 16px);
      }
    }
    @media (min-width: 1824px) {
      & .card {
        width: calc(16.66666% - 16px);
      }
    }
  }
`;

export const EmptyData = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  margin-top: 25px;
  padding-bottom: 25px;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
`;
