import React, { useReducer, createContext } from 'react';

enum EType {
  'SET_DOCUMENTS_DATA',
  'SET_POSITIONS_DATA',
}

interface IAppState {
  readonly allPositions?: any[];
  readonly allDocuments?: any[];
}

interface IAppAction {
  readonly type?: keyof typeof EType;
  readonly payload?: IAppState;
}

interface IAppContextData {
  readonly state: IAppState | undefined;
  readonly dispatch: (data: IAppAction) => void;
}

const initialState: IAppState = {
  allDocuments: [],
  allPositions: [],
};

const AppReducer = (
  state: IAppState = initialState,
  action: IAppAction = {}
) => {
  switch (action.type) {
    case 'SET_DOCUMENTS_DATA':
      return { ...state, allDocuments: action.payload?.allDocuments };
    case 'SET_POSITIONS_DATA':
      return { ...state, allPositions: action.payload?.allPositions };
    default:
      return { ...initialState };
  }
};

export const AppContext = createContext<IAppContextData>({} as IAppContextData);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
