// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppDocumentsWrapper = styled.div``;
export const AppDocumentsHeader = styled.div``;
export const AppDocumentsBody = styled.div`
  display: none;
  @media (min-width: 721px) {
    display: initial;
  }
`;
export const EmptyData = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  margin-top: 25px;
  padding-bottom: 25px;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
`;
export const AppDocumentsAccordion = styled.div`
  display: initial;
  .wrapper {
    .header {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
      padding: 15px 0;
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        height: 1px;
        width: 100%;
        left: -100%;
        bottom: -1px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.gray200};
      }
      &:before {
        content: '';
        height: 1px;
        width: 100%;
        right: -100%;
        bottom: -1px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.gray200};
      }
    }
    .body {
      padding: 20px 0;
    }
  }
  @media (min-width: 721px) {
    display: none;
  }
`;
export const AppDocumentsSections = styled.div`
  position: relative;
  &:after {
    content: '';
    height: 1px;
    width: 100%;
    left: -100%;
    bottom: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray200};
  }
  &:before {
    content: '';
    height: 1px;
    width: 100%;
    right: -100%;
    bottom: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray200};
  }
`;

export const AppDocumentsListHeader = styled.div`
  margin-top: 45px;
  margin-bottom: 20px;
`;

export const AppDocumentsListBody = styled.div`
  display: flex;
  flex-direction: row;
  /* padding-top: 40px;
	padding-bottom: 35px; */
  position: relative;
  gap: 86px;
`;

export const AppDocumentsMenu = styled.div`
  width: 30%;
`;

export const AppDocumentsListDescription = styled.div`
  flex-grow: 2;
  padding-left: 40px;
  padding-right: 40px;
`;
export const AppDocumentsListFiles = styled.div`
  flex: 1;
`;

export const AppDocumentsListFilesHeader = styled.div`
  margin-bottom: 15px;
`;
export const AppDocumentsListFilesBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const AppDocumentsListFilesAction = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const AppDocumentsListDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;
