// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppContainerWrapper = styled.div`
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
`;

export const AppContainerPageWrapper = styled.div`
  padding-top: 50px;
  flex: 1;
  width: 90%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 75%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: 1160px;
  }
`;
