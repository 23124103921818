import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getItemFromLocalstorage } from './../../helpers';
import { trackRestFetch, trackError, dataDogTags } from './../../analytics';

const API = process.env.REACT_APP_REST_URL;

const config: AxiosRequestConfig = {
  baseURL: API,
};

function requestInterceptor(confInterceptor: any) {
  const token = getItemFromLocalstorage('@Auth:token', 'STRING');
  const version = getItemFromLocalstorage('version', 'STRING');

  if (confInterceptor && confInterceptor.headers && token) {
    confInterceptor.headers.Authorization = `${token}`;
    confInterceptor.headers.version = `${version}`;
  }
  confInterceptor.baseURL = config.baseURL;
  trackRestFetch(confInterceptor);
  return confInterceptor;
}

function requestError(error: any) {
  return Promise.reject(error);
}

function responseError(error: any) {
  return Promise.reject(error);
}

const configHTTP = (): AxiosInstance => {
  const baseHTTP = axios.create(config);
  baseHTTP.interceptors.request.use(
    conf => {
      return requestInterceptor(conf);
    },
    (error: any) => {
      trackError(dataDogTags.fetch.errors.rest, { error });
      return requestError(
        'Algo deu errado. Por favor, tente novamente em alguns instantes ou envie um email para ri@voxcapital.com.br'
      );
    }
  );
  baseHTTP.interceptors.response.use(
    response => {
      return response;
    },
    (error: any) => {
      trackError(dataDogTags.fetch.errors.rest, { error });
      return responseError(
        'Algo deu errado. Por favor, tente novamente em alguns instantes ou envie um email para ri@voxcapital.com.br'
      );
    }
  );
  return baseHTTP;
};

const HTTP = configHTTP();

export { HTTP };
