// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const PageTitleWrapper = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 75%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 50%;
  }
`;
