/* eslint-disable no-useless-escape */
// Component generated with util/vox-create-component.js
import React from 'react';
import { AuthCommons } from './../../ui';
import {
  AuthForgotPasswordProps,
  ForgotPasswordData,
} from './AuthForgotPassword.types';
import { string, object, SchemaOf } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { UI } from './../../component';
import AuthContext from './../../context/AuthContext';
import AlertContext from './../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import {
  authResendCodeVerification,
  authForgotPasswordSubmit,
} from './../../service/aws/auth';
import { EAlertType } from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import { dataDogTags, trackView } from '../../analytics';

// Component
export const AuthForgotPassword: React.FC<AuthForgotPasswordProps> = props => {
  trackView(
    dataDogTags.views.authForgotPassword,
    dataDogTags.views.authForgotPassword
  );
  // Context
  const { resetUserName } = React.useContext(AuthContext);
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  // languages
  const [t] = useTranslation('auth');
  const [e] = useTranslation('errors');
  // Validations
  const ForgotPasswordValidation: SchemaOf<ForgotPasswordData> = object().shape(
    {
      code: string().required(e('errors.auth.code.required')),
      password: string()
        .required(e('errors.auth.password.required'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          e('errors.auth.password.notmatch')
        ),
    }
  );
  // States
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  // Redirect if user is not in the context
  if (!resetUserName && history) history.push('/AuthSignIn');
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordData>({
    resolver: yupResolver(ForgotPasswordValidation),
    mode: 'onSubmit',
  });
  // Send password form
  const onSubmit = handleSubmit(async ({ code, password }) => {
    if (!resetUserName) return;
    setLoading(true);
    const { error } = await authForgotPasswordSubmit(
      resetUserName.username,
      code,
      password
    );
    setLoading(false);
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
      return;
    }
    showAlert({
      type: EAlertType.success,
      title: 'Senha atualizada',
      message:
        'A sua senha foi atualizada com sucesso, faça login novamente com a sua senha nova',
      primaryAlertAction: () => {
        history.push('/AuthSignIn');
        closeAlert();
      },
      primaryLabel: 'Continuar',
    });
    history.push('/AuthSignIn');
  });
  const requestAccessToken = async () => {
    setLoading(true);
    const { error } = await authResendCodeVerification(resetUserName.username);
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
      return;
    }
    showAlert({
      type: EAlertType.success,
      title: 'Novo código',
      message: error?.message || 'Um novo código foi enviado com sucesso',
    });
    setLoading(false);
  };
  // Component
  return (
    <AuthCommons.AuthCommonContainer>
      {/* <AuthCommons.AuthCommonHeader>
        <UI.VoxButton
          label=''
          rightAddon={Icons.arrow_back}
          preset={EButtonPreset.link}
          color={Colors.light}
          onClick={() => history.goBack()}
        />
      </AuthCommons.AuthCommonHeader> */}
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            {/* <UI.VoxText
              preset={TextSizes.heading5}
              weight={EFontWeight.bold}
              data-subtitle
              color={Colors.gray500}
            >
              {t('auth.forgotPassword.label')}
            </UI.VoxText>
            <div className='h-2' /> */}
            <UI.VoxText
              preset={TextSizes.heading5}
              color={Colors.gray500}
              weight={EFontWeight.medium}
              data-subtitle
            >
              {t('auth.forgotPassword.title')}
            </UI.VoxText>
            <div className='h-2' />
            <UI.VoxText
              preset={TextSizes.heading3}
              color={Colors.pink800}
              weight={EFontWeight.bold}
            >
              {t('auth.forgotPassword.description').replace(
                '@destination',
                resetUserName && resetUserName.CodeDeliveryDetails
                  ? resetUserName.CodeDeliveryDetails.Destination
                  : 'para seu e-mail cadastrado'
              )}
            </UI.VoxText>
            <div className='h-2' />
            <UI.VoxText
              color={Colors.light}
              preset={TextSizes.body}
              weight={EFontWeight.bold}
              underline
              isLink
              onClick={() => history.goBack()}
            >
              {t('auth.forgotPassword.forms.button_change_email.label')}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInput
              label=''
              placeholder={t(
                'auth.forgotPassword.forms.input_code.placeholder'
              )}
              isTexthidden
              hasError={!!errors.code}
              errorMessage={errors.code?.message}
              isDisabled={isLoading}
              dark
              {...register('code')}
            />
            <UI.VoxInput
              label=''
              placeholder={t(
                'auth.forgotPassword.forms.input_password.placeholder'
              )}
              isTexthidden
              hasError={!!errors.password}
              errorMessage={errors.password?.message}
              isDisabled={isLoading}
              dark
              {...register('password')}
            />
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxButton
              label={t('auth.forgotPassword.forms.button_new_token.label')}
              color={Colors.light}
              preset={EButtonPreset.link}
              onClick={requestAccessToken}
              underline
              isBlock
            />
            <UI.VoxButton
              label={t('auth.forgotPassword.forms.button_send.label')}
              preset={EButtonPreset.solid}
              color={Colors.light}
              onClick={onSubmit}
              isLoading={isLoading}
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthForgotPassword;
