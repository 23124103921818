// Component generated with util/vox-create-component.js
import React from 'react';
import { AuthSignUpProps, SignUpData } from './AuthSignUp.types';
import { useHistory } from 'react-router-dom';
import { string, object, SchemaOf, boolean } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UI } from './../../component';
import { AuthCommons } from './../../ui';
import { useTranslation } from 'react-i18next';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import { dataDogTags, trackView } from '../../analytics';

export const AuthSignUp: React.FC<AuthSignUpProps> = props => {
  trackView(dataDogTags.views.authSignUp, dataDogTags.views.authSignUp);
  // languages
  const [t] = useTranslation('auth');
  // States
  const [isLoading, setLoading] = React.useState(false);
  // History
  const history = useHistory();
  // Validations
  const SignUpValidation: SchemaOf<SignUpData> = object().shape({
    name: string().required(t('auth.signup.forms.input_name.error')),
    phone: string().required(t('auth.signup.forms.input_phone.error')),
    email: string().email().required(t('auth.signup.forms.input_email.error')),
    term: boolean()
      .required()
      .oneOf([true], t('auth.signup.forms.checkbox_term.error')),
  });
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpData>({
    resolver: yupResolver(SignUpValidation),
    mode: 'onSubmit',
  });
  // Send signup form
  const onSubmit = handleSubmit(async ({ ...args }) => {
    setLoading(true);
    setTimeout(() => {
      alert('On submit: sign up form');
      setLoading(false);
    }, 3000);
  });
  React.useEffect(() => {
    return () => {};
  }, [errors]);
  return (
    <AuthCommons.AuthCommonContainer>
      <AuthCommons.AuthCommonHeader>
        <UI.VoxButton
          preset={EButtonPreset.solid}
          color={Colors.dark200}
          onClick={() => history.goBack()}
        />
      </AuthCommons.AuthCommonHeader>
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText preset={TextSizes.body} weight={EFontWeight.medium}>
              {t('auth.signup.label')}
            </UI.VoxText>
            <UI.VoxText
              preset={TextSizes.body}
              color={Colors.light}
              weight={EFontWeight.bold}
            >
              {t('auth.signup.title')}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInput
              label={t('auth.signup.forms.input_name.label')}
              placeholder={t('auth.signup.forms.input_name.placeholder')}
              hasError={!!errors.name}
              errorMessage={errors.name?.message}
              isDisabled={isLoading}
              dark
              {...register('name')}
            />
            <UI.VoxInput
              label={t('auth.signup.forms.input_email.label')}
              placeholder={t('auth.signup.forms.input_email.placeholder')}
              hasError={!!errors.email}
              errorMessage={errors.email?.message}
              isDisabled={isLoading}
              dark
              {...register('email')}
            />
            <UI.VoxInput
              label={t('auth.signup.forms.input_phone.label')}
              placeholder={t('auth.signup.forms.input_phone.placeholder')}
              hasError={!!errors.phone}
              errorMessage={errors.phone?.message}
              isDisabled={isLoading}
              dark
              {...register('phone')}
            />
            <UI.VoxCheckBox
              label={t('auth.signup.forms.checkbox_term.label')}
              value={true}
              hasError={!!errors.term}
              errorMessage={errors.term?.message}
              dark
              {...register('term')}
            />
            <br />
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <span />
            {/* <UI.VoxText color="Tertiary" preset="button" isLink onClick={() => history.goBack()}>
							{t('auth.signup.forms.button_back.label')}
						</UI.VoxText> */}
            <UI.VoxButton
              label={t('auth.signup.forms.button_send.label')}
              preset={EButtonPreset.outline}
              color={Colors.cyan}
              onClick={onSubmit}
              isLoading={isLoading}
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthSignUp;
