// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppAccountsWrapper = styled.div``;

export const AuthAccountsList = styled.div`
  margin-bottom: 10px;
`;

export const AuthAccount = styled.div<{ isSelected: boolean }>`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  /* padding: 12px 15px; */
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.pink800 : theme.colors.pink200};
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 10px;
  > div {
    flex: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;
