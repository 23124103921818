// Component generated with util/vox-create-component.js
import * as R from 'ramda';
import React from 'react';
import { AppAreaImpactoProps } from './AppAreaImpacto.types';
import { UI, AppImpactDetail } from './../';
import { getImageDownloadLink } from './../../service/api';
import { Kaleidoscope } from './../../graphql/types.d';
import { disableScroll, enableScroll } from './../../helpers';
import { client } from './../../service/vimeo';
import { Colors, EFontWeight, TextSizes } from '@vox-capital/vox-ui-package';
import { dataDogTags } from '../../analytics';

export const CPNHistoryData: React.FC<{ history: Kaleidoscope }> = ({
  history,
}) => {
  // States
  const [imagesUrls, setImagesUrls] = React.useState([]);
  const [videosUrls, setVideosUrls] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showDetail, setDetail] = React.useState(false);
  // Effects
  React.useEffect(() => {
    if (!history) return;
    (async () => {
      setLoading(true);
      const allImages: any[] = [...(history.imageId || []).filter(img => img)];
      const allVideos: any[] = [...(history.videoId || []).filter(vid => vid)];
      if (!R.isEmpty(allVideos)) {
        const getVideosUrls = await Promise.all([
          ...allVideos.reduce(
            (acc: any, next: any) => [
              ...acc,
              new Promise(resolve => {
                client.request(
                  `/videos/${R.last(R.split('/', next))}`,
                  (error, body, status, headers) => {
                    if (error) resolve('');
                    if (body) resolve(body);
                  }
                );
              }),
            ],
            []
          ),
        ]);
        setVideosUrls(
          getVideosUrls
            .filter((vid: any) => vid)
            .reduce(
              (acc: any, next: any) => [...acc, next.player_embed_url],
              []
            )
        );
      }
      if (!R.isEmpty(allImages)) {
        const getImagesUrls = await Promise.all([
          ...allImages.reduce(
            (acc: any, next: any) => [
              ...acc,
              new Promise(resolve => {
                resolve(getImageDownloadLink(next));
              }),
            ],
            []
          ),
        ]);
        setImagesUrls(
          getImagesUrls.reduce(
            (acc: any, next: any) => [...acc, next.fileUrl],
            []
          )
        );
      }
      setLoading(false);
    })();
  }, [history]);
  // handlers
  const toggleDetail = async () => {
    if (showDetail) enableScroll();
    else disableScroll();
    setDetail(!showDetail);
  };
  // Component
  return (
    <>
      <UI.VoxImpactHistory
        data-vox-action={dataDogTags.actions.home.impact.card}
        key={history.id}
        description={history.subtitle || ''}
        image={imagesUrls[0]}
        onClick={toggleDetail}
        title={history.title || ''}
        loading={loading}
      />
      {showDetail && (
        <AppImpactDetail
          impactData={{
            ...history,
            imageId: [...imagesUrls],
            videoId: [...videosUrls],
          }}
          onCloseDetail={toggleDetail}
        />
      )}
    </>
  );
};

export const AppAreaImpacto: React.FC<AppAreaImpactoProps> = ({
  header,
  impactHistory = [],
  footer,
  ...args
}) => {
  return (
    <div
      className='vox-history-impact flex flex-col md:flex-row gap-8 w-full'
      {...args}
    >
      {header && (
        <div className='header flex flex-col gap-4 w-full md:w-4/12'>
          <UI.VoxText preset={TextSizes.heading4} weight={EFontWeight.bold}>
            {header.commonMessage}
            {header.coloredMessage}
          </UI.VoxText>
          <UI.VoxText
            preset={TextSizes.bodyLg}
            weight={EFontWeight.bold}
            color={Colors.gray500}
          >
            Conheça evidências de como seus investimentos estão colaborando para
            um mundo 100% regenerado para as pessoas, cidades e planeta.
          </UI.VoxText>
        </div>
      )}
      <div className='items grid grid-cols-3 items-center align-middle justify-between py-8'>
        {impactHistory.map((i: Kaleidoscope) => (
          <CPNHistoryData key={i.id} history={i} />
        ))}
      </div>
      {footer && <div className='footer'>{footer.commonMessage}</div>}
    </div>
  );
};

export default AppAreaImpacto;
