// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppVideosWrapper = styled.div`
  position: relative;
  .videos {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .modal {
    position: fixed;
    /* background-color: ${({ theme }) => theme.colors.darkBlue200}90; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .overlay {
      position: fixed;
      background-color: ${({ theme }) => theme.colors.darkBlue200}90;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    .content {
      width: 820px;
      padding: 60px 40px;
      background-color: ${({ theme }) => theme.colors.light};
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: relative;
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      .video {
        margin-top: 25px;
        iframe {
          width: 100%;
          height: 460px;
          border: none !important;
          border-radius: 8px;
        }
      }
    }
  }
`;

export const AppVideo = styled.div<{ img: any }>`
  width: calc(33% - 90px);
  background-color: ${({ theme }) => theme.colors.gray200};
  height: 200px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  border-radius: 8px;
  background-image: url(${({ theme, img }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  :before {
    content: '';
    background-color: ${({ theme }) => theme.colors.darkBlue}50;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
`;
