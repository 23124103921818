// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppImpactDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  .header,
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    overflow: clip;
  }
  .header {
    align-items: flex-start;
    margin-top: 0px;
    text-align: left;
    .formattext {
      width: 100%;
      border: none;
      white-space: pre-wrap;
    }
  }
  .body {
    .images,
    .videos {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .images {
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-wrap: wrap;
        .img {
          width: 100%;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
        }
      }
    }
    .videos {
      .video {
        .frame {
          background: ${({ theme }) => theme.colors.gray100};
          border: none;
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px 40px;
    width: auto;
    .header,
    .body {
      gap: 25px;
      overflow: initial;
    }
    .header {
      margin-top: 40px;
    }
    .body {
      .images,
      .videos {
        .content {
          display: flex;
          flex-direction: row;
          gap: 20px;
          flex-wrap: wrap;
          .img {
            width: calc(50% - 20px);
            background-size: 'cover';
            background-position: 'center';
            border-radius: 8;
          }
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
  }
`;
