// Component generated with util/vox-create-component.js
import React from 'react';
import { AppVideosWrapper, AppVideo } from './AppVideos.styled';
import { AppVideosProps } from './AppVideos.types';
import { getVimeoVideo } from './../../service/vimeo';
import { motion } from 'framer-motion';
import {
  VoxText as Text,
  VoxButton as Button,
  Colors,
  TextSizes,
  EFontWeight,
  EButtonPreset,
} from '@vox-capital/vox-ui-package';
import Player from '@vimeo/player';
import { dataDogTags, trackView } from '../../analytics';

export const AppVideos: React.FC<AppVideosProps> = ({ ...args }) => {
  trackView(dataDogTags.views.privateVideos, dataDogTags.views.privateVideos);
  const ref = React.useRef(null);
  const [videoData, setVideoData] = React.useState<any>();
  const [currentVideo, setVideo] = React.useState<any>();
  React.useEffect(() => {
    (async () => {
      const response = await getVimeoVideo('/videos/691972578');
      setVideoData(response);
    })();
  }, []);
  React.useEffect(() => {
    (async () => {
      if (currentVideo && ref.current) {
        const player = new Player(ref.current, {
          autoplay: true,
          controls: false,
        });
        await player.play();
        player.on('play', onVideoPlay);
        player.on('ended', onVideoEnded);
      }
    })();
  }, [currentVideo, ref]);
  if (!videoData) {
    return (
      <div {...args}>
        <span>Loading</span>
      </div>
    );
  }
  // Handlers
  const onVideoPlay = (data: any) => {};
  const onVideoEnded = (data: any) => {};
  // player_embed_url
  return (
    <AppVideosWrapper {...args}>
      <div className='videos'>
        <AppVideo
          className='video'
          img={videoData.pictures.base_link}
          onClick={() => setVideo(videoData)}
        >
          <Text
            preset={TextSizes.heading4}
            weight={EFontWeight.bold}
            color={Colors.yellow}
          >
            {videoData.name}
          </Text>
          <Text color={Colors.light}>{videoData.description}</Text>
        </AppVideo>
        <AppVideo
          className='video'
          img={videoData.pictures.base_link}
          onClick={() => setVideo(videoData)}
        >
          <Text
            preset={TextSizes.heading4}
            weight={EFontWeight.bold}
            color={Colors.yellow}
          >
            {videoData.name}
          </Text>
          <Text color={Colors.light}>{videoData.description}</Text>
        </AppVideo>
        <AppVideo
          className='video'
          img={videoData.pictures.base_link}
          onClick={() => setVideo(videoData)}
        >
          <Text
            preset={TextSizes.heading4}
            weight={EFontWeight.bold}
            color={Colors.yellow}
          >
            {videoData.name}
          </Text>
          <Text color={Colors.light}>{videoData.description}</Text>
        </AppVideo>
      </div>
      {currentVideo && (
        <div className='modal'>
          <motion.div
            className='overlay'
            layout
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                display: 'none',
                opacity: 0,
              },
              visible: {
                display: 'initial',
                opacity: 1,
              },
            }}
          />
          <motion.div
            className='content'
            layout
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                display: 'none',
                y: 100,
              },
              visible: {
                display: 'initial',
                y: 52,
              },
            }}
          >
            <div className='close'>
              <Button
                preset={EButtonPreset.link}
                color={Colors.dark200}
                onClick={() => setVideo(null)}
              />
            </div>
            <Text preset={TextSizes.heading2} weight={EFontWeight.bold}>
              {videoData.name}
            </Text>
            <Text color={Colors.dark200}>{videoData.description}</Text>
            <div className='video'>
              <iframe
                title={videoData.name}
                src={videoData.player_embed_url}
                ref={ref}
              />
            </div>
          </motion.div>
        </div>
      )}
    </AppVideosWrapper>
  );
};

export default AppVideos;
