import styled from 'styled-components';

export const DisclaimerWrapper = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .description {
    position: absolute;
    bottom: calc(100% + 10px);
    left: -20px;
    width: 512px;
    height: fit-content;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.darkBlue};
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.pink800};
    border-style: solid;
    ::before {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      left: 20px;
      bottom: -12px;
      background-color: ${({ theme }) => theme.colors.light};
      transform: rotate(-45deg);
      border-left-width: 2px;
      border-bottom-width: 2px;
      border-left-color: ${({ theme }) => theme.colors.pink800};
      border-bottom-color: ${({ theme }) => theme.colors.pink800};
      border-left-style: solid;
      border-bottom-style: solid;
      border-bottom-left-radius: 4px;
    }
  }
`;
