import { Vimeo } from 'vimeo';

const client = new Vimeo(
  `${process.env.REACT_APP_VIMEO_CLIENT_ID}`,
  `${process.env.REACT_APP_VIMEO_CLIENT_SECRET}`,
  `${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`
);

const getVimeoVideo = async (path: string) => {
  const response = await client.request(
    {
      method: 'GET',
      path,
    },
    (error, body, status, headers) => {}
  );
  return response;
};

// const getVimeoVideo = (path: string): Promise<CompleteCallback> => {
// 	console.log('getVimeoVideo: ', path);
// 	return new Promise((response, reject) => {
// 		client.request(
// 			{
// 				method: 'GET',
// 				path,
// 			},
// 			(error, body, status, headers) => {
// 				if (error) {
// 					reject(error);
// 				}
// 				response(body);
// 			},
// 		);
// 	});
// };

export { getVimeoVideo, client };
