import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
  useQuery,
  useMutation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getItemFromLocalstorage } from './../../helpers';
import { trackError, dataDogTags, trackGraphqlFetch } from './../../analytics';

// Http Link connection
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'same-origin',
});

// Middleware
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const storageToken = getItemFromLocalstorage('@Auth:token', 'STRING');
    const impersonateid = getItemFromLocalstorage(
      '@Auth:impersonateid',
      'STRING'
    );
    const version = getItemFromLocalstorage('version', 'STRING');
    return {
      headers: {
        ...headers,
        authorization: storageToken || null,
        impersonateid,
        version,
      },
    };
  });

  trackGraphqlFetch(operation);

  return forward(operation);
});

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ extensions = {}, message, locations, path, ...args }) => {
        trackError(dataDogTags.fetch.errors.graphql, {
          environment: process.env.REACT_APP_ENV_NAME,
          message,
          locations,
          path,
          extensions,
          ...args,
        });
        switch (extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.clear();
            break;
          default:
            break;
        }
      }
    );
  }
  if (networkError) {
  }
});

export const client = new ApolloClient({
  link: from([error, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    resultCaching: true,
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          statementInput: {
            keyArgs: false,
            read: () => {},
          },
        },
      },
    },
  }),
});

export const setupClient = ({ queryResponse }: any) => queryResponse;

export { useQuery, useMutation };
