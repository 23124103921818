// Component generated with util/vox-create-component.js
import React from 'react';
import { AppLoadingWrapper } from './AppLoading.styled';
import { AppLoadingProps } from './AppLoading.types';
import { Loader } from './../../ui';

export const AppLoading: React.FC<AppLoadingProps> = ({ ...args }) => {
  // TODO: Move this logic to storybook-project
  return (
    <AppLoadingWrapper {...args}>
      <Loader message='Trazendo seu legado' />
    </AppLoadingWrapper>
  );
};

export default AppLoading;
