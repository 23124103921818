import { HTTP } from './../http';

export const getImageDownloadLink = async (imageId: string): Promise<any> => {
  try {
    const result = await HTTP.get<any>(`/documents/url/${imageId}`);
    if (result.status !== 200) throw new Error('Something went wrong');
    return result.data;
  } catch (error) {
    return error;
  }
};

export const downLoadDocumentFile = async (file: any): Promise<any> => {
  try {
    const { status, data } = await HTTP.get(`/documents/url/${file}`);
    if (status !== 200) throw new Error('Error ao buscar o documento');
    const filename = data.name;
    if (data) {
      await fetch(data.fileUrl, {
        method: 'GET',
        mode: 'cors',
        redirect: 'manual',
      })
        .then(response => response.blob())
        .then(response => {
          const objectURL = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = objectURL;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(e => {
          throw new Error(e);
        });
    }
  } catch (error) {
    return {
      error,
    };
  }
};
