// Component generated with util/vox-create-component.js
import React from 'react';
import { LoadingWrapper } from './Loading.styled';
import { LoadingProps } from './Loading.types';
import { UI } from './../../component';
import {
  Colors,
  EFontWeight,
  // Icons,
  TextSizes,
  VoxLoadingIcon,
} from '@vox-capital/vox-ui-package';

export const Loading: React.FC<LoadingProps> = ({
  loadingMessage,
  ...args
}) => {
  return (
    <LoadingWrapper {...args}>
      <div className='content'>
        <div className='loading' style={{ display: 'initial' }}>
          <div className='h-6 w-6'>
            <div role='status'>
              <VoxLoadingIcon color={Colors.pink800} />
            </div>
          </div>
        </div>
        {loadingMessage ? (
          <div className='message'>
            <UI.VoxText
              color={Colors.light}
              preset={TextSizes.bodyMd}
              weight={EFontWeight.medium}
            >
              {loadingMessage}
            </UI.VoxText>
          </div>
        ) : (
          <></>
        )}
      </div>
    </LoadingWrapper>
  );
};

export default Loading;
