import React, { useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import AuthContext from './../context/AuthContext';
import {
  AuthSignIn,
  AuthRecovery,
  AuthSignUp,
  AuthNewPassword,
  AuthAccessToken,
  AuthForgotPassword,
  AuthChooseAccount,
  AppHome,
  AppDocuments,
  AppExtracts,
  AppLoading,
  AppVideos,
} from './../pages';
import { AppContainer as AppLoggedContainer } from './../component';
import { AppContainer } from './../ui';

const GlobalStyle = createGlobalStyle<{ dark?: boolean }>`
	body {
		height: 100vh;
		background: ${({ theme, dark }) =>
      dark ? `${theme.colors.darkBlue100};` : theme.colors.light};
	}
	#root {
		height: 100vh;
	}
`;

interface RoutePropsChild extends RouteProps {
  children?: React.ReactNode;
}

const PrivateRouter: React.FC<RoutePropsChild> = ({ children, ...args }) => {
  const { signed } = useContext(AuthContext);
  return (
    <Route
      {...args}
      render={({ location }) =>
        signed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/AuthSignIn',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Router: React.FC = () => {
  const { signed, appLoading, appError } = useContext(AuthContext);
  if (appError) {
    return <div>{JSON.stringify(appError)}</div>;
  }
  if (appLoading) {
    return <AppLoading />;
  }
  if (signed) {
    return (
      <BrowserRouter>
        <GlobalStyle />
        <AppLoggedContainer>
          <Switch>
            {/* Private routes */}
            <PrivateRouter path='/AppHome' component={AppHome} />
            <PrivateRouter path='/AppDocuments' component={AppDocuments} />
            <PrivateRouter path='/AppExtracts' component={AppExtracts} />
            <PrivateRouter path='/AppVideos' component={AppVideos} />
            <Route component={() => <Redirect to='/AppHome' />} />
          </Switch>
        </AppLoggedContainer>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <GlobalStyle dark />
      <AppContainer>
        <Switch>
          {/* Public routes */}
          <Route component={AuthSignIn} path='/AuthSignIn' />
          <Route component={AuthRecovery} path='/AuthRecovery' />
          <Route component={AuthSignUp} path='/AuthSignUp' />
          <Route component={AuthNewPassword} path='/AuthNewPassword' />
          <Route component={AuthForgotPassword} path='/AuthForgotPassword' />
          <Route component={AuthChooseAccount} path='/AuthChooseAccount' />
          <Route
            component={AuthAccessToken}
            path='/AuthAccessToken/:CODE_DELIVERY_DESTINATION/:CODE_DELIVERY_DELIVERY_MEDIUM'
          />
          <Route component={() => <Redirect to='/AuthSignIn' />} />
        </Switch>
      </AppContainer>
    </BrowserRouter>
  );
};

export default Router;
