// Component generated with util/vox-create-component.js
import React from 'react';
import {
  Colors,
  EButtonPreset,
  Icons,
  VoxButton,
  VoxLayoutContainer,
} from '@vox-capital/vox-ui-package';
import voxLogoPink from './../../assets/images/vox-logo-pink.svg';
import { useHistory } from 'react-router-dom';
import { dataDogTags } from '../../analytics';

export const AppContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
  ...args
}) => {
  const history = useHistory();
  return (
    <div className='flex flex-col h-full' {...args}>
      <div
        id='vox-auth-header'
        className='flex justify-between items-center px-4 md:px-6'
      >
        <div className='image'>
          <img
            className='cursor-pointer'
            src={voxLogoPink}
            alt='Vox capital dark logo'
            width='128'
            onClick={() => history.push('AuthSignIn')}
          />
        </div>
        <div className='navigation flex gap-2 md:gap-4'>
          <VoxButton
            preset={EButtonPreset.link}
            color={Colors.light}
            label='FluxoVOX'
            onClick={() =>
              window.open('https://fluxo.voxcapital.com.br/', '_blank')
            }
            rightAddon={Icons.north_east}
            responsive
            data-vox-action={dataDogTags.actions.auth.fluxo}
          />
          <VoxButton
            preset={EButtonPreset.link}
            color={Colors.light}
            label='Ir para o site da VOX'
            onClick={() => window.open('https://voxcapital.com.br/', '_blank')}
            rightAddon={Icons.north_east}
            responsive
            data-vox-action={dataDogTags.actions.auth.site}
          />
        </div>
      </div>
      <div className='w-full h-full absolute' style={{ zIndex: -1 }}>
        <div
          className='flex-1 h-full hidden md:block md:w-4/12 lg:w-6/12'
          style={{
            zIndex: -1,
            backgroundPosition: 'center',
            backgroundImage: `url('https://voxcapital.com.br/wp-content/uploads/2022/10/vox-capital1.jpg')`,
          }}
        />
        <div className='sm:w-10/12 md:w-8/12 lg:w-6/12' />
      </div>
      <div
        id='vox-auth-body'
        className='flex justify-center flex-1 items-center'
      >
        <VoxLayoutContainer>
          <div className='flex flex-col md:flex-row gap-14 items-center w-full h-full'>
            <div className='hidden md:block md:w-4/12 lg:w-6/12'>
              {/* <video autoPlay muted loop>
                <source
                  src='https://frontend-assets-voxri.s3.sa-east-1.amazonaws.com/vox-video-cube.mp4'
                  type='video/mp4'
                />
              </video> */}
            </div>
            <div
              id='vox-auth-form'
              className='w-full sm:w-10/12 md:w-8/12 lg:w-6/12 md:px-8 lg:px-14'
            >
              {children}
            </div>
          </div>
        </VoxLayoutContainer>
      </div>
    </div>
  );
};

export default AppContainer;
