// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { AuthCommons } from './../../ui';
import { AuthNewPasswordProps, NewPasswordData } from './AuthNewPassword.types';
import { string, object, SchemaOf, ref, bool } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';
import { UI } from './../../component';
import AuthContext from './../../context/AuthContext';
import AlertContext from './../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import {
  authResetPassowrd,
  authForgotPasswordSubmit,
  authSignInService,
} from './../../service/aws/auth';
import { saveItemToLocalstorage } from './../../helpers';
import { AuthTermForm } from './AuthNewPassword.styled';
import { EAlertType } from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import { dataDogTags, trackView } from '../../analytics';

// Component
export const AuthNewPassword: React.FC<AuthNewPasswordProps> = props => {
  trackView(
    dataDogTags.views.authNewPassword,
    dataDogTags.views.authNewPassword
  );
  // languages
  const [t] = useTranslation('auth');
  const [e] = useTranslation('errors');
  const [c] = useTranslation('common');
  // Validations
  const NewPasswordValidation: SchemaOf<NewPasswordData> = object().shape({
    password: string()
      .required(e('errors.auth.password.required'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        e('errors.auth.password.notmatch')
      ),
    confirmPassword: string()
      .required(e('errors.auth.password.required'))
      .oneOf([ref('password'), null], e('errors.auth.password.confirm')),
    terms: bool()
      .required()
      .oneOf([true], 'Você precisa concordar com o termo'),
  });
  // Context
  const {
    clearSession,
    setSession,
    setTempSession,
    resetUserName,
    saveUserName,
    getProfile,
    getDependents,
    acceptTerm,
  } = React.useContext(AuthContext);
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  // States
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  const { AUTH_CODE } = useParams<{ AUTH_CODE: string }>();
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordData>({
    resolver: yupResolver(NewPasswordValidation),
    mode: 'onSubmit',
  });
  // Redirect if user is not in the context
  // if (!resetUserName && history && !AUTH_CODE) history.push('/AuthSignIn');

  // Send password form
  const onSubmit = handleSubmit(async ({ confirmPassword, ...args }) => {
    if (!resetUserName) return;
    setLoading(true);
    const { data, error } = AUTH_CODE
      ? await authForgotPasswordSubmit(
          resetUserName.username,
          AUTH_CODE,
          confirmPassword
        )
      : await authResetPassowrd(resetUserName, confirmPassword);
    setLoading(false);
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
      return;
    } else if (
      data.challengeName === 'SMS_MFA' ||
      data.challengeName === 'MFA_SETUP'
    ) {
      saveUserName(data);
      history.push(
        `/AuthAccessToken/${data.challengeParam.CODE_DELIVERY_DESTINATION}/${data.challengeParam.CODE_DELIVERY_DELIVERY_MEDIUM}`
      );
      return;
    }
    setLoading(true);
    try {
      let token = data.signInUserSession?.idToken?.jwtToken;
      saveItemToLocalstorage('@Auth:token', token, 'STRING');

      const loginId = data.signInUserSession.accessToken.payload.sub;
      const term = await acceptTerm(`${loginId}`);
      if (!term) {
        clearSession();
        showAlert({
          type: EAlertType.error,
          title: `${c('errors.common.wrong.title')}`,
          message: `${c('errors.common.default.message')}`,
          closeAlert: clearSession,
        });
        return;
      }

      const { data: dataSignIn } = await authSignInService(
        resetUserName.username,
        confirmPassword
      );
      token = dataSignIn.signInUserSession?.idToken?.jwtToken;
      saveItemToLocalstorage('@Auth:token', token, 'STRING');
      const profile: any = await getProfile({
        userName: resetUserName.username,
        id: loginId,
      });
      saveItemToLocalstorage('version', profile.version, 'STRING');
      const profiles = await getDependents(`${profile?.personId}`);
      if (profiles && !R.isEmpty(profiles)) {
        setTempSession({
          user: profile,
          profiles,
          aToken: token,
        });
        history.push('/AuthChooseAccount');
        return;
      } else if (!profile || R.isEmpty(profile)) {
        showAlert({
          type: EAlertType.error,
          title: `${t('auth.messages.userNotFound.title')}`,
          message: `${t('auth.messages.userNotFound.description')}`,
          primaryAlertAction: () => {
            saveUserName(data);
            history.push('/AuthNewPassword');
            closeAlert();
          },
          primaryLabel: 'Continuar',
          secondaryLabel: 'Fechar',
        });
      } else {
        setSession({
          user: profile,
          profiles,
          aToken: token,
        });
      }
    } catch (error) {
      clearSession();
      showAlert({
        type: EAlertType.error,
        title: `${c('errors.common.wrong.title')}`,
        message: `${c('errors.common.default.message')}`,
      });
    }
    setLoading(false);
  });
  // Component
  return (
    <AuthCommons.AuthCommonContainer>
      {/* <AuthCommons.AuthCommonHeader>
        <UI.VoxButton
          preset={EButtonPreset.solid}
          color={Colors.dark200}
          onClick={() => history.goBack()}
        />
      </AuthCommons.AuthCommonHeader> */}
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText
              preset={TextSizes.heading5}
              color={Colors.gray500}
              weight={EFontWeight.medium}
            >
              {t('auth.newPassword.label')}
            </UI.VoxText>
            <UI.VoxText
              preset={TextSizes.heading3}
              color={Colors.pink800}
              weight={EFontWeight.bold}
            >
              {t('auth.newPassword.title')}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInput
              label=''
              placeholder={t(
                'auth.newPassword.forms.input_password.placeholder'
              )}
              isTexthidden
              hasError={!!errors.password}
              errorMessage={errors.password?.message}
              isDisabled={isLoading}
              data-testid='vox-password-input'
              dark
              {...register('password')}
            />
            <UI.VoxInput
              label=''
              placeholder={t(
                'auth.newPassword.forms.input_confirm_password.placeholder'
              )}
              isTexthidden
              hasError={!!errors.confirmPassword}
              errorMessage={errors.confirmPassword?.message}
              isDisabled={isLoading}
              data-testid='vox-confirm-input'
              dark
              {...register('confirmPassword')}
            />
            <AuthTermForm>
              <UI.VoxCheckBox
                label='Eu li, estou ciente do Termo de Adesão e das condições de tratamento dos meus dados pessoais e dou meu consentimento, quando aplicável, conforme descrito nesta Política de Privacidade.'
                value={true}
                data-testid='vox-checkbox-term'
                dark
                {...register('terms')}
              />
              <div className='link'>
                <div className='links'>
                  <UI.VoxText
                    color={Colors.light}
                    isLink
                    onClick={() =>
                      window.open(process.env.REACT_APP_TERMS, '_blank')
                    }
                    weight={EFontWeight.bold}
                  >
                    Termo de Adesão
                  </UI.VoxText>
                  <UI.VoxText color={Colors.light}>e</UI.VoxText>
                  <UI.VoxText
                    color={Colors.light}
                    isLink
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_PRIVACY_POLICIES,
                        '_blank'
                      )
                    }
                    weight={EFontWeight.bold}
                  >
                    Política de Privacidade
                  </UI.VoxText>
                </div>
                {!!errors.terms && errors.terms.message && (
                  <div className='error'>
                    <UI.VoxText color={Colors.red} preset={TextSizes.body}>
                      {errors.terms.message}
                    </UI.VoxText>
                  </div>
                )}
              </div>
            </AuthTermForm>
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxButton
              label={t('auth.newPassword.forms.button_back.label')}
              preset={EButtonPreset.link}
              color={Colors.light}
              onClick={() => history.push('/AuthSignIn')}
              isBlock
            />
            <UI.VoxButton
              label={t('auth.newPassword.forms.button_send.label')}
              preset={EButtonPreset.solid}
              color={Colors.light}
              onClick={onSubmit}
              isLoading={isLoading}
              data-testid='vox-newpassword-btn'
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthNewPassword;
