import React, { createContext, useState } from 'react';
import { UI } from './../component';
import { disableScroll, enableScroll } from './../helpers';
import { EAlertType } from '@vox-capital/vox-ui-package';

export type AlertData = {
  title: string;
  message: string;
  type: EAlertType;
  primaryAlertAction?: () => void;
  primaryLabel?: string;
  secondaryAlertAction?: () => void;
  secondaryLabel?: string;
  closeAlert?: () => void;
};

export interface IAlertContextData {
  showAlert: (alert: AlertData) => void;
  closeAlert: () => void;
}

export const AlertContext = createContext<IAlertContextData>(
  {} as IAlertContextData
);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertData, setAlertData] = useState<AlertData | null>();

  const showAlert = (alert: AlertData) => {
    disableScroll();
    setAlertData({
      title: alert.title,
      type: alert.type,
      message: alert.message,
      primaryAlertAction: alert.primaryAlertAction,
      primaryLabel: alert.primaryLabel,
      secondaryAlertAction: alert.secondaryAlertAction,
      secondaryLabel: alert.secondaryLabel,
      closeAlert: alert.closeAlert,
    });
  };
  const closeAlert = () => {
    enableScroll();
    setAlertData(null);
  };
  return (
    <AlertContext.Provider
      value={{
        showAlert,
        closeAlert,
      }}
    >
      {alertData && (
        <UI.VoxAlert
          data-testid='vox-alert'
          image={false}
          title={alertData.title}
          message={alertData.message}
          type={alertData.type}
          primaryAction={alertData.primaryAlertAction || closeAlert}
          secondaryAction={alertData.secondaryAlertAction || closeAlert}
          onCloseAlert={alertData.closeAlert || closeAlert}
          primaryActionLabel={
            alertData.primaryLabel ||
            (alertData.primaryAlertAction ? 'Aceitar' : 'Ok')
          }
          secondaryActionLabel={
            alertData.secondaryLabel ||
            (alertData.secondaryAlertAction ? 'Fechar' : '')
          }
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
