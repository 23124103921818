// Component generated with util/vox-create-component.js
import React from 'react';
import {
  AppProfileWrapper,
  AppProfileActionsWrapper,
} from './AppProfile.styled';
import { AppProfileProps, ProfileFormData } from './AppProfile.types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../context/AuthContext';
import { useForm } from 'react-hook-form';
import { UI } from './../../component';
import { Colors, EButtonPreset } from '@vox-capital/vox-ui-package';

const MAILTO_SENDER = process.env.REACT_APP_MAILTO_PROFILE;
const MAILTO_SUBJECT = 'Solicitar alterações';

export const AppProfile: React.FC<AppProfileProps> = ({
  toggleProfile,
  ...args
}) => {
  // Ref
  const contactRef = React.useRef<HTMLAnchorElement>(null);
  // languages
  const [t] = useTranslation('auth');
  // Context
  const { user } = React.useContext(AuthContext);
  // Validations
  // Form
  const { register } = useForm<ProfileFormData>({
    // resolver: yupResolver(AuthFormValidation),
    mode: 'onSubmit',
    defaultValues: {
      email: user?.email || 'cliente@voxcapital.com.br',
      phone: user?.phone,
    },
  });
  return (
    <AppProfileWrapper {...args}>
      <a
        rel='noreferrer'
        ref={contactRef}
        href={`mailto:${MAILTO_SENDER}?subject=${MAILTO_SUBJECT}`}
        target='_blank'
        style={{ display: 'none' }}
      >
        Contact
      </a>
      <UI.VoxModal
        subTitle={t('auth.profile.label')}
        title={user?.name || user?.userName || 'Cliente Vox'}
        closeModalAction={toggleProfile}
      >
        {user?.email && (
          <UI.VoxInput
            label={t('auth.signin.forms.input_email.label')}
            placeholder='e-mail'
            {...register('email')}
            isDisabled
          />
        )}
        {user?.phone && (
          <UI.VoxInput
            label={t('auth.signin.forms.input_phone.label')}
            placeholder={t('auth.signin.forms.input_phone.placeholder')}
            {...register('phone')}
            isDisabled
          />
        )}
        <AppProfileActionsWrapper>
          <UI.VoxButton
            preset={EButtonPreset.solid}
            color={Colors.dark200}
            label={t('auth.profile.button_request_update')}
            isBlock
            onClick={() => {
              if (contactRef && contactRef.current) contactRef.current.click();
            }}
          />
        </AppProfileActionsWrapper>
      </UI.VoxModal>
    </AppProfileWrapper>
  );
};

export default AppProfile;
