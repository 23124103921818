// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AuthNewPasswordContainer = styled.div``;

export const AuthTermForm = styled.div`
  .link {
    padding-left: 38px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
`;
