import Amplify, { Auth } from 'aws-amplify';

type AuthResponse = {
  readonly data?: any;
  readonly error?: any;
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_APP_CLIENT_ID,
  },
});

const authSignInService = async (
  user: string,
  password: string
): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.signIn(user, password)
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authConfirmSignInService = async (
  user: any,
  code: string,
  challengeName: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.confirmSignIn(user, code, challengeName)
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authSetupTOTP = async (user: any): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.setupTOTP(user)
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authSignOut = async (): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.signOut()
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authCurrentSession = async (): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.currentSession()
      .then(response => {
        resolve({ data: { ...response, isValid: response.isValid() } });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authCurrentAuthenticatedUser = async (): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.currentAuthenticatedUser()
      .then(response => {
        resolve({ data: { ...response, a: response.authenticated } });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authForgotPassword = async (user: string): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.forgotPassword(user)
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authForgotPasswordSubmit = async (
  user: string,
  code: string,
  password: string
): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.forgotPasswordSubmit(user, code, password)
      .then(response => {
        resolve({ data: response });
      })
      .catch(error => {
        resolve({ error });
      });
  });
};

const authResetPassowrd = async (
  user: any,
  newPassword: string
): Promise<AuthResponse> => {
  return new Promise(resolve => {
    Auth.completeNewPassword(user, newPassword)
      .then((response: any) => {
        resolve({ data: response });
      })
      .catch((error: any) => {
        resolve({ error });
      });
  });
};

const authResendCodeVerification = async (
  user: string
): Promise<AuthResponse> => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(user)
      .then((response: any) => {
        resolve({ data: response });
      })
      .catch((error: any) => {
        resolve({ error });
      });
  });
};

export {
  authSignInService,
  authConfirmSignInService,
  authForgotPassword,
  authForgotPasswordSubmit,
  authSetupTOTP,
  authSignOut,
  authCurrentAuthenticatedUser,
  authCurrentSession,
  authResetPassowrd,
  authResendCodeVerification,
  Auth,
  Amplify,
};
