// Component generated with util/vox-create-component.js
import styled from 'styled-components';
import { motion } from 'framer-motion';

/**
 * Components
 */
export const AppCookieWrapper = styled(motion.div)`
  padding: 20px 30px;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 16px;
  display: flex;
  gap: 50px;
  width: 90%;
  z-index: 9999;
  bottom: 20px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  .info {
    width: 75%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 75%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: 1060px;
  }
`;
