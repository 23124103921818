import * as R from 'ramda';
import crypto from 'crypto-js';
// import uniqid from 'uniqid';
import moment from 'moment';
import { StatementFile } from './../@types/statement';
import jspdf from 'jspdf';
import VoxLogoPink from '../assets/base64/vox-logo-pink';
import { tableHeader, tableConfig } from './../constants';
import {
  Fund,
  Maybe,
  Position,
  Asset,
  Kaleidoscope,
} from './../graphql/types.d';
import uniqid from 'uniqid';
import '../assets/fonts/RedHatDisplay-medium';
import '../assets/fonts/RedHatDisplay-bold';

import jsPDF from 'jspdf';

/**
 * Function para setar uma cookie
 * @param key Key da cooki
 * @param value Valor da cooki
 * @param expire En quantos dias a cookie vai expirar
 * @param path Rota onde a cookie pode ser lida
 */
export const setCookie = (
  key: string,
  value: string,
  expire: any,
  path: string = '/'
) => {
  const expiredIn = new Date();
  expiredIn.setDate(expiredIn.getDate() + expire);
  document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
    value
  )}; expires=${expiredIn.toUTCString()}; path=${path}`;
};
/**
 * Retorna o valor da cookie
 * @param key Key da cookie
 * @returns o value da cookie
 */
export const getCookie = (key: string) => {
  const cookie = document.cookie.split(';').filter((c: string) => {
    return c.trim().startsWith(key);
  });
  // const value = (cookie[0] || '').split('=')[1];
  return (cookie[0] || '').split('=')[1];
};
/**
 * Limpa todas as cookies
 */
export const clearCookie = () => {};
/**
 *
 * @returns Retorna uma lista com as cookies
 */
export const getAllCookies = () => document.cookie.split(';');
/**
 * Desabilita a opção de scroll
 */
export const disableScroll = () => {
  const scrollTop = window.pageYOffset || document.body.scrollTop;
  const scrollLeft = window.pageXOffset || document.body.scrollLeft;
  window.onscroll = () => {
    window.scrollTo(scrollLeft, scrollTop);
  };
};

/**
 * Habilita a opção de scroll
 */
export const enableScroll = () => {
  window.onscroll = () => {};
};

/**
 * Remove all whitespaces
 * @param val O texto
 * @returns O texto sem espaços
 */
export const removeWhiteSpaces = (val: string) => {
  return val.replace(/\s/g, '');
};

/**
 * Retorna dados de impacto
 * @param positions Lita de positions
 * @returns Retorna todos os dados de impacto das positions
 */
export const formatImpactData = (positions: any[] = []) => {
  const data = positions
    .reduce((acc: any, next: Position) => {
      const assets: any = next.fund ? next.fund.assets || [] : [];
      const impacts = assets.reduce(
        (a: any, n: Asset) => [...a, ...(n.impacts?.kaleidoscopeList || [])],
        []
      );
      return R.unionWith(R.eqBy<any, any>(R.prop('id')), acc, impacts);
    }, [])
    .map((d: Kaleidoscope) => ({
      ...d,
      createAt: moment(d.createdAt).format('DD/MM/YYYY'),
    }));
  return R.sortWith<any>([R.ascend(R.prop('createAt'))])(data);
};

export const formatDocumentsData = (
  positions: Position[] = [],
  documents: Document[] = []
) => {
  const data = positions
    .reduce(
      (acc: any, next: Position) => {
        const docs: any = next.fund
          ? (next.fund.documentList || [])
              .filter(d => d?.highlights === 'Destaque' && d?.highlights)
              .map(d => ({ ...d, fund: next.fund?.name }))
          : [];
        return R.unionWith(R.eqBy<any, any>(R.prop('id')), acc, docs);
      },
      [
        ...documents.map((doc: any) => ({
          ...doc,
          type: doc.name || doc.type || 'Documento VOX Capital',
          fund: 'Gestora VOX',
        })),
      ]
    )
    .map((d: any) => ({ ...d, date: moment(`${d.date}`, 'DD/MM/YYYY') }))
    .sort((a, b) => b - a)
    .splice(0, 6);
  return R.sortWith<any>([R.ascend(R.prop('createAt'))])(data);
};

/**
 *
 * @param data Retorna a lista de pontos no gráfico formatados
 * @param data Lista de dados para montar o gráfico
 * @returns
 */
export const formatGraphData = (historyData: any) => {
  if (!historyData) return [];
  const keys = Object.keys(historyData);
  const { date } = historyData;
  const colors = ['#EE5FA8', '#1F1E22', '#ED975B'];
  const getLegendLable = (name: string) => {
    switch (name) {
      case 'position':
        return 'Patrimônio Bruto';
      case 'integralization':
        return 'Valor Aplicado';
      case 'amortization':
        return 'Valor Amortizado';
      default:
        return 'Outros';
    }
  };
  return keys
    .filter(
      k =>
        !['profitability', 'date'].includes(k) &&
        historyData[k] &&
        !R.isEmpty(historyData[k])
    )
    .splice(1, keys.length)
    .reduce((acc: any, nxt: any, k: number) => {
      return [
        ...acc,
        {
          id: nxt.trim(),
          name: nxt,
          label: getLegendLable(nxt),
          color: colors[k],
          items: [...(date || [])].map((da: any, i: number) => ({
            id: `${da}`,
            date: new Date(moment(da).format()),
            profitability: `${transFormNumber(
              historyData.profitability[i] || 0.0
            )}%`,
            positive: historyData.profitability[i] >= 0,
            value: parseFloat(historyData[nxt][i]),
          })),
        },
      ];
    }, []);
};

/**
 * Retorna a lista de documentos no formato certo
 * @param data Lista de posicoes sem formatar
 * @param types Tipos de documentos disponiveis na lista
 * @returns
 */
export const getDocuments = (data: any, types: string[]) => {
  return R.map(type => {
    const reducing = R.reduce(
      (acc: any, next: any) => {
        const { fund, documentList = [] } = next;
        const fundDocs = fund.documentList || [];
        const docsFilter = R.filter(
          doc => doc.type === type,
          [...fundDocs].concat(documentList || [])
        );
        return [
          ...acc,
          {
            id: uniqid(),
            name: type,
            section: fund.id,
            files: docsFilter.length
              ? [
                  ...docsFilter.map(({ id, date, type: docType, name }) => ({
                    id,
                    dateCreation: date,
                    name,
                    type: docType,
                    url: '/',
                  })),
                ]
              : [],
          },
        ];
      },
      [],
      data
    );
    return reducing;
  }, types).reduce((acc, next) => {
    return [...acc, ...next];
  }, []);
};

/**
 * Retorna a lista de typos de documentos
 * @param data Lista de posicoes sem formatar
 * @returns
 */
export const getDocumentsTypes = (dataPositions: any) => {
  return R.reduce(
    (acc: any, next: Position) => {
      const { fund, documentList = [] } = next;
      const fundDocs = fund?.documentList || [];
      const fundDoccTypes = R.reduce(
        (a: any, n: any) => [...a, n.type],
        [],
        fundDocs
      );
      const globalDoccTypes = R.reduce(
        (a: any, n: any) => [...a, n.type],
        [],
        documentList || []
      );
      return R.union(acc, R.union(fundDoccTypes, globalDoccTypes));
    },
    [],
    dataPositions
  );
};

/**
 * Retorna a lista de fundos (grupo de fundos)
 * @param data Lista de posicoes sem formatar
 * @returns
 */
export const getSectionsFiles = (data: any) => {
  return R.reduce(
    (acc: any, next: any) => {
      const { id, name } = next.fund;
      const section = {
        id,
        name: id,
        label: name,
      };
      return [...acc, { ...section }];
    },
    [
      {
        id: 'vox-documents',
        name: 'vox-documents',
        label: 'Gestora Vox Capital',
      },
    ],
    data
  );
};

export const saveItemToLocalstorage = (
  indexName: string,
  data: any,
  format: 'STRING' | 'JSON'
) => {
  if (!indexName || !data) {
    return;
  }

  try {
    localStorage.setItem(
      indexName,
      commonEncryptAES(data, `${process.env.REACT_APP_VOX_SECRET}`, format)
    );
  } catch (error) {
    localStorage.clear();
  }
};

export const getItemFromLocalstorage = (
  indexName: string,
  format: 'STRING' | 'JSON'
) => {
  const item = localStorage.getItem(indexName);
  if (!item) {
    return;
  }
  try {
    return commonDecryptAES(
      item,
      `${process.env.REACT_APP_VOX_SECRET}`,
      format
    );
  } catch (error) {
    localStorage.clear();
  }
};

/**
 * Encrypt a data in AES format
 * @param data Data to encrypt
 * @param key Key for the data to encrypt
 * @returns return a encrypted data
 */
export const commonEncryptAES = (
  data: any,
  key: string,
  format: 'STRING' | 'JSON'
) => {
  switch (format) {
    case 'JSON':
      return crypto.AES.encrypt(JSON.stringify(data), key).toString();
    default:
      return crypto.AES.encrypt(data, key).toString();
  }
};

/**
 * Decrypt any data in AES format
 * @param encrypt encrypted data
 * @param key encrypted key
 * @returns return a decrypted data
 */
export const commonDecryptAES = (
  encrypt: any,
  key: string,
  format: 'STRING' | 'JSON'
) => {
  const bytes = crypto.AES.decrypt(encrypt, key);
  switch (format) {
    case 'JSON':
      return JSON.parse(bytes.toString(crypto.enc.Utf8));
    default:
      return bytes.toString(crypto.enc.Utf8);
  }
};
/**
 * Function converte uma data em um código do mês
 * Os códigos estão no src/translation/common
 * @param date Date to extract month code
 */
export const getMonthName = (date: string) => {
  return moment(date).locale(getBrowserLang()).format('MMMM');
};

/**
 * Obtem a liguagem do browser
 * @returns Devolve a lamguange do Browser
 */
export const getBrowserLang = () => 'pt_br';
// export const getBrowserLang = () => navigator.language.toLocaleLowerCase().replace('-', '_');

/**
 * Formato um valor numérico para moeda
 * @param val Valor para ser convertido
 * @returns O valor dado em formato de moeda
 */
export const transFormCurreny = (val: any) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(val);
};
/**
 * Formatar numeros para padrao BR
 * @param val Valor para ser convertido
 * @returns O Valor formateado
 */
export const transFormNumber = (val: any) => {
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
    val
  );
};

/**
 * Formato um valor numérico para moeda
 * @param val Valor para ser convertido
 * @returns O valor dado em formato de moeda
 */
export const transFormDecimal = (val: Maybe<number> | undefined) => {
  if (val) {
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 8 }).format(
      val
    );
  }
};

/**
 * Formato um valor numérico em %
 * @param val Valor para ser convertido
 */
export const transFormPercent = (val: any) => {
  if (val?.toFixed(2)) {
    return val.toFixed(2) + '%';
  } else {
    return '-';
  }
};

/**
 * Imprimir extrato
 * @param statement Extrato
 */
export const printStatement = (statement: Fund, user: any) => {
  const operations: any[] = statement.operations
    ? statement.operations.map(operation => {
        return {
          data: `${moment(operation?.date).format('DD/MM/YYYY')}`,
          description: `${operation?.description}`,
          gross_value: `${transFormCurreny(operation?.grossValue || 0)}`,
          tax: `${transFormCurreny(operation?.tax || 0)}`,
          net_value: `${transFormCurreny(operation?.netValue || 0)}`,
          quota_used: `R$ ${transFormDecimal(operation?.quotaValue) || 0.0}`,
          quota_number: `${transFormDecimal(operation?.quotaQuantity) || 0.0}`,
          quota_balance: `${transFormDecimal(operation?.quotaBalance) || 0.0}`,
        };
      })
    : [];
  const period = {
    start: moment(statement.startDate).startOf('month').format('DD/MM/YYYY'),
    end: moment(statement.startDate).endOf('month').format('DD/MM/YYYY'),
    month: moment(statement.startDate).startOf('month').format('MMMM'),
  };
  const regexId = /^[0-9]{11}$/;
  const hasMatchCpfId = regexId.test(user.userName);
  const maskedId = hasMatchCpfId
    ? user?.userName.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    : user?.userName.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
  return createStatementFile({
    issueDate: moment().format('DD/MM/YYYY'),
    dataTable: [...operations],
    owner: {
      name: `${user?.name || ''}`,
      typeId: 'CPF',
      id: `${user.userName ? maskedId : ''}`,
    },
    monthProfitability: `${transFormPercent(statement.monthProfitability)}`,
    threeMonthProfitability: `${transFormPercent(
      statement.threeMonthProfitability
    )}`,
    sixMonthProfitability: `${transFormPercent(
      statement.sixMonthProfitability
    )}`,
    twelveMonthProfitability: `${transFormPercent(
      statement.twelveMonthProfitability
    )}`,
    twentyFourMonthProfitability: `${transFormPercent(
      statement.twentyFourMonthProfitability
    )}`,
    lastYearProfitability: `${transFormPercent(
      statement.lastYearProfitability
    )}`,
    thirtySixMonthProfitability: `${transFormPercent(
      statement.thirtySixMonthProfitability
    )}`,
    performance: `${transFormPercent(statement.totalProfitability)}`,
    productName: `${statement.name}`,
    period,
    currentQuota: statement.currentQuota,
    lastQuota: statement.lastQuota || {},
  });
};

/**
 * Function que gera um documento (extrato) em formato pdf
 * @param issueDate Data de emissão
 * @param period Periodo do extrato
 * @param performance Rentabilidade do mês
 * @param productName Nome do fundo | produto
 * @param owner Nome do usuario logado
 * @param dataTable Histórico das operações do cotista
 * @param currentQuota Estado da cota no mês atual
 * @param lastQuota Estado da cota no mês anterior
 * @param monthProfitability Rentabilidade do fundo | produto no mês
 * @param sixMonthProfitability Rentabilidade do fundo | produto nos 6 úlitmos meses
 * @param twelveMonthProfitability Rentabilidade do fundo | produto nos 12 úlitmos meses
 * @param lastYearProfitability Rentabilidade do fundo | produto no úlitmo ano
 */
export const createStatementFile = (props: StatementFile) => {
  const {
    issueDate,
    dataTable,
    owner,
    performance,
    period,
    productName,
    currentQuota,
    lastQuota,
    monthProfitability,
    sixMonthProfitability,
    twelveMonthProfitability,
    lastYearProfitability,
  } = props;
  const doc = new jspdf({
    format: [842, 595],
    orientation: 'landscape',
    unit: 'px',
    userUnit: 1,
    precision: 16,
    floatPrecision: 'smart',
    compress: true,
  });

  doc.setFillColor('#FCFBF9');
  doc.rect(0, 0, 842, 595, 'F');

  doc.setFillColor('#F8F7F1');
  doc.rect(0, 0, 842, 62, 'F');

  doc.setFillColor('#FFFFFF');
  doc.rect(0, 595 - 62, 842, 62, 'F');

  doc.addImage(
    `data:image/png;base64,${VoxLogoPink}`,
    'png',
    30,
    30,
    100,
    51,
    undefined,
    'FAST'
  );

  doc.setFont('RedHatDisplay-Normal', 'normal');

  doc.setFontSize(10);
  doc.setTextColor('#686F81');
  doc.text(`Emissão \n${issueDate || '@issuedDate'}`, 812, 30, {
    align: 'right',
    maxWidth: 100,
  });

  doc.setFontSize(12);
  doc.setTextColor('#F10181');
  doc.text(`Período: ${period.month}`, 782, 186, {
    align: 'right',
  });

  doc.setFontSize(24);
  doc.setTextColor('#2C3241');
  doc.setFont('RedHatDisplay-Bold', 'bold');
  doc.text(productName || '@productName', 60, 143, {
    align: 'left',
    maxWidth: 700,
  });

  doc.setFont('RedHatDisplay-Normal', 'normal');

  doc.setFontSize(12);

  doc.setTextColor('#686F81');
  doc.text('Extrato consolidado', 60, 118);
  doc.text(`${owner.name} - ${owner.id}` || '@ownerName', 60, 186, {
    align: 'left',
  });

  // Data Table
  doc.setDrawColor('#FFFFFF');
  doc.setFont('helvetica', 'normal');
  doc.table(52, 219, dataTable, tableHeader, tableConfig);

  doc.setDrawColor('#F1ECE2');
  doc.setLineWidth(1);
  doc.line(0, 243, 842, 243);

  // Footer
  doc.setFontSize(14);
  doc.setTextColor('#686F81');
  doc.setFont('RedHatDisplay-Bold', 'bold');
  doc.text(
    `Resumo de\n${moment(lastQuota.date).format('DD/MM/YYYY')}`,
    60,
    510,
    {
      maxWidth: 100,
      align: 'left',
    }
  );
  doc.setTextColor('#1F1E22');
  doc.text(
    `Resumo de\n${moment(currentQuota.date).format('DD/MM/YYYY')}`,
    452,
    510,
    {
      maxWidth: 100,
      align: 'left',
    }
  );

  doc.setFont('RedHatDisplay-Normal', 'normal');
  doc.setTextColor('#ABB4CC');
  doc.setFontSize(10);

  doc.text('Cota', 160, 510, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text('PL do fundo', 260, 510, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text('Cota', 552, 510, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text('PL do fundo', 652, 510, {
    maxWidth: 100,
    align: 'left',
  });

  doc.setFont('RedHatDisplay-Bold', 'bold');
  doc.setTextColor('#686F81');

  doc.text(`${transFormCurreny(lastQuota?.value || 0.0)}`, 160, 520, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text(`${transFormCurreny(lastQuota?.pl || 0.0)}`, 260, 520, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text(`${transFormCurreny(currentQuota?.value || 0.0)}`, 552, 520, {
    maxWidth: 100,
    align: 'left',
  });

  doc.text(`${transFormCurreny(currentQuota?.pl || 0.0)}`, 652, 520, {
    maxWidth: 100,
    align: 'left',
  });

  doc.setTextColor('#F10181');
  doc.text('Rentabilidade do fundo no tempo', 60, 550);
  doc.text('Rentabilidade acumulada do fundo', 452, 550);

  doc.setFontSize(24);
  doc.setFont('RedHatDisplay-Bold', 'bold');
  doc.text(performance || '@performance', 452, 572);

  doc.setDrawColor('#FFFFFF');
  doc.setTextColor('#686F81');
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.table(
    58,
    558,
    [
      {
        improve1: `${monthProfitability}`,
        improve3: `${sixMonthProfitability}`,
        improve4: `${twelveMonthProfitability}`,
        improve5: `${lastYearProfitability}`,
      },
    ],
    [
      {
        name: 'improve1',
        align: 'left',
        padding: 0,
        width: 70,
        prompt: 'Mês',
      },
      {
        name: 'improve3',
        align: 'left',
        padding: 0,
        width: 70,
        prompt: '6 meses',
      },
      {
        name: 'improve4',
        align: 'left',
        padding: 0,
        width: 70,
        prompt: '12 meses',
      },
      {
        name: 'improve5',
        align: 'left',
        padding: 0,
        width: 70,
        prompt: `Ano`,
      },
    ],
    {
      padding: 2,
      headerBackgroundColor: '#FFFFFF',
      headerTextColor: '#ABB4CC',
      fontSize: 10,
    }
  );
  doc.addPage();
  doc.setFontSize(12);
  doc.setFont('RedHatDisplay-Normal', 'normal');
  doc.setTextColor('#686F81');
  doc.text('Observações:', 60, 118);
  doc.text(
    [
      "1. A apuração da rentabilidade é baseada na variação do último dia útil do mês anterior ao período de cálculo e do último dia útil do mês de referência ('30 a 30').",
      '2. A rentabilidade passada não é garantia de rentabilidade futura.',
      '3. Para avaliação da performance de Fundos de Investimento, é recomendável uma análise de período mínimo de 12 (doze) meses.Fundos de Investimento não contam com a garantia do administrador do fundo de investimento, do administrador da carteira ou do Fundo Garantidor de Créditos (FGC).',
      '4. Antes do investimento inicial no Fundo, recomenda-se a leitura atenta do respectivo prospecto (quando existente) e regulamento disponibilizados. Especial atenção deve ser dada às cláusulas relativas ao objetivo e à política de investimento do fundo, bem como às disposições que tratam dos fatores de risco a que está exposto.',
      '5. As informações constantes no presente extrato, apesar de fiéis aos registros existentes nesta instituição financeira em nome do respectivo cotista, não devem ser utilizadas para avaliação de efeitos fiscais das operações realizadas e declarações à Receita Federal. Os dados necessários às declarações à Receita Federal devem ser obtidos nos informes de rendimentos disponibilizados por esta instituição',
    ],
    60,
    150,
    {
      align: 'left',
      maxWidth: 700,
    }
  );

  return doc;
};

export const saveDocument = (doc: jsPDF, fileName?: string) => {
  doc.save(`${fileName || `vox-capital-${uniqid()}`}.pdf`);
};

export const encode = (value: string) => {
  return window.atob(unescape(encodeURIComponent(value)));
};

export const decode = (value: string) => {
  return decodeURIComponent(escape(window.atob(value)));
};
