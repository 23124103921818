// Component generated with util/vox-create-component.js
import styled from 'styled-components';
import { AppNavigationProps } from './AppNavigation.types';

/**
 * Components
 */
export const AppNavigationWrapper = styled.div<AppNavigationProps>`
  /* display: flex; */
  /* flex-direction: row; */
  width: 100%;
  /* width: 300px;
	height: 100vh; */
  /* border-right-style: solid;
	border-right-width: 1px;
	border-right-color: ${({ theme }) => theme.colors.gray200}; */
`;

export const AppNavigationHeader = styled.div`
  margin-bottom: 40px;
`;

export const AppNavigationBody = styled.div`
  flex: 1;
`;
