// Component generated with util/vox-create-component.js
import React from 'react';
import { AppAccountsWrapper, AuthAccount } from './AppAccounts.styled';
import { AppAccountsProps } from './AppAccounts.types';
import { AuthContext } from './../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { UI } from './../../component';

export const AppAccounts: React.FC<AppAccountsProps> = ({
  toggleProfile,
  ...args
}) => {
  // languages
  const [t] = useTranslation('auth');
  // Context
  const { allProfiles, updateSession, user, setSession, accessToken } =
    React.useContext(AuthContext);
  // State
  const [account, setAccount] = React.useState<any>(user);
  // Actions
  const changeUser = () => {
    updateSession(account);
    toggleProfile();
    setSession({
      user: account,
      profiles: allProfiles,
      aToken: `${accessToken}`,
    });
    // @todo: dispach without reload
    window.location.reload();
  };
  return (
    <AppAccountsWrapper {...args}>
      <UI.VoxModal
        title={t('auth.myAccount.title')}
        subTitle={t('auth.myAccount.label')}
        closeModalAction={toggleProfile}
        primaryActionLabel={t('auth.myAccount.actions.button_request_access')}
        primaryAction={changeUser}
      >
        <div className='flex flex-col px-8 py-8'>
          {allProfiles.map(acc => (
            <AuthAccount
              key={acc.id}
              onClick={() =>
                setAccount(account && acc.id === account.id ? null : acc)
              }
              isSelected={acc.id === account?.id}
            >
              <UI.VoxAvatar size='small' title={`${acc.name}`} />
            </AuthAccount>
          ))}
        </div>
      </UI.VoxModal>
    </AppAccountsWrapper>
  );
};

export default AppAccounts;
