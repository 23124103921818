// Component generated with util/vox-create-component.js
import React, { useState } from 'react';
import { AppCookieWrapper } from './AppCookie.styled';
import { AppCookieProps } from './AppCookie.types';
import { UI } from './../../component';
import { setCookie } from './../../helpers';
import { Colors, EButtonPreset } from '@vox-capital/vox-ui-package';

export const AppCookie: React.FC<AppCookieProps> = ({ ...args }) => {
  const [visible, setVisible] = useState(true);
  const acceptCookies = () => {
    setVisible(false);
    setTimeout(() => {
      setCookie('voxcookie', 'true', 7);
    }, 500);
  };
  return (
    <AppCookieWrapper
      {...args}
      variants={{
        hidden: {
          y: '200%',
          x: '-50%',
        },
        visible: {
          y: 0,
          x: '-50%',
        },
      }}
      initial='hidden'
      transition={{
        duration: 0.4,
        ease: 'backInOut',
      }}
      animate={visible ? 'visible' : 'hidden'}
    >
      <div className='info'>
        <UI.VoxText color={Colors.darkBlue}>
          Armazenamos cookies em seu dispositivo para proporcionar uma melhor
          experiência. Ao utilizar esse site, você concorda com nossa{' '}
          <b
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() =>
              window.open(process.env.REACT_APP_PRIVACY_POLICIES, '_blank')
            }
          >
            política de privacidade.
          </b>
        </UI.VoxText>
      </div>
      <div className='action'>
        <UI.VoxButton
          label='Permitir cookies'
          onClick={acceptCookies}
          color={Colors.pink800}
          preset={EButtonPreset.solid}
        />
      </div>
    </AppCookieWrapper>
  );
};

export default AppCookie;
