import { CellConfig, TableConfig } from 'jspdf';

export const graphTags = [
  {
    id: 1,
    label: '1A',
    value: 12,
  },
  {
    id: 2,
    label: '3A',
    value: 36,
  },
  {
    id: 3,
    label: 'TODOS',
    value: 9999,
  },
];

export const tableConfig: TableConfig = {
  fontSize: 10,
  headerBackgroundColor: '#FCFBF9',
  headerTextColor: '#686F81',
  margins: 0,
  padding: 8,
  printHeaders: true,
};

export const tableHeader: CellConfig[] = [
  {
    name: 'data',
    align: 'left',
    padding: 0,
    width: 88,
    prompt: 'Data',
  },
  {
    name: 'description',
    align: 'left',
    padding: 0,
    width: 280,
    prompt: 'Descrição',
  },
  {
    name: 'gross_value',
    align: 'left',
    padding: 0,
    width: 110,
    prompt: 'Valor Bruto',
  },
  {
    name: 'tax',
    align: 'left',
    padding: 0,
    width: 110,
    prompt: 'Taxas',
  },
  {
    name: 'net_value',
    align: 'left',
    padding: 0,
    width: 110,
    prompt: 'Valor Líquido',
  },
  {
    name: 'quota_used',
    align: 'left',
    padding: 0,
    width: 110,
    prompt: 'Valor da Cota',
  },
  {
    name: 'quota_number',
    align: 'left',
    padding: 0,
    width: 100,
    prompt: 'Qtd. de Cotas',
  },
  {
    name: 'quota_balance',
    align: 'left',
    padding: 0,
    width: 110,
    prompt: 'Saldo de Cotas',
  },
];
