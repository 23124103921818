// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { AuthSignInProps, AuthFormData } from './AuthSignIn.types';
import { UI } from './../../component';
import { useForm } from 'react-hook-form';
import { SchemaOf, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthContext from './../../context/AuthContext';
import AlertContext from './../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import { authSignInService } from './../../service/aws/auth';
import { useHistory } from 'react-router-dom';
import { AuthCommons } from './../../ui';
import { commonEncryptAES, saveItemToLocalstorage } from './../../helpers';
import { EAlertType } from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';

import { dataDogTags, trackView } from '../../analytics';

// Component
export const AuthSignIn: React.FC<AuthSignInProps> = ({ ...args }) => {
  trackView(dataDogTags.views.authSignIn, dataDogTags.views.authSignIn);
  // languages
  const [t] = useTranslation('auth');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // contexts
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  const {
    setSession,
    setTempSession,
    saveUserName,
    saveUserScret,
    getProfile,
    getDependents,
    clearSession,
  } = React.useContext(AuthContext);
  // states
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  // Validations
  const AuthFormValidation: SchemaOf<AuthFormData> = object().shape({
    userName: string().required(t('auth.signin.forms.input_user.error')),
    userPassword: string().required(
      t('auth.signin.forms.input_password.error')
    ),
  });
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },

    watch,
  } = useForm<AuthFormData>({
    resolver: yupResolver(AuthFormValidation),
    mode: 'onSubmit',
  });
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'userName' && value.userName) {
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  // Actions
  const onSubmit = handleSubmit(async ({ userName, userPassword }) => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    const response = await authSignInService(userName, userPassword);
    const { data: dataSignIn, error } = response || {};
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: `Oops!!`,
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : c(`errors.common.default.message`),
      });
      setLoading(false);
      return;
    } else if (dataSignIn.challengeName === 'NEW_PASSWORD_REQUIRED') {
      saveUserName(dataSignIn);
      history.push('/AuthNewPassword');
      return;
    } else if (dataSignIn.challengeName === 'SMS_MFA') {
      saveUserName(dataSignIn);
      saveUserScret(commonEncryptAES(userPassword, 'USER_KEY', 'STRING'));
      history.push(
        `/AuthAccessToken/${dataSignIn.challengeParam.CODE_DELIVERY_DESTINATION}/${dataSignIn.challengeParam.CODE_DELIVERY_DELIVERY_MEDIUM}`
      );
      return;
    }
    try {
      // Getting token from cognito
      const token = dataSignIn.signInUserSession?.idToken?.jwtToken;
      saveItemToLocalstorage('@Auth:token', token, 'STRING');
      const profile: any = await getProfile({
        userName,
        id: dataSignIn.attributes?.sub,
      });
      saveItemToLocalstorage('version', profile.version, 'STRING');
      const profiles = await getDependents(`${profile?.personId}`);
      if (profiles && !R.isEmpty(profiles)) {
        setTempSession({
          user: profile,
          profiles,
          aToken: token,
        });
        history.push('/AuthChooseAccount');
        return;
      } else if (R.isEmpty(profile)) {
        showAlert({
          type: EAlertType.error,
          title: `${t('auth.messages.userNotFound.title')}`,
          message: `${t('auth.messages.userNotFound.description')}`,
          primaryAlertAction: () => {
            saveUserName(dataSignIn);
            history.push('/AuthNewPassword');
            closeAlert();
          },
          primaryLabel: 'Continuar',
          secondaryLabel: 'Fechar',
        });
        return;
      } else {
        setSession({
          user: profile,
          profiles,
          aToken: token,
        });
      }
    } catch (error) {
      clearSession();
      showAlert({
        type: EAlertType.error,
        title: `${c('errors.common.wrong.title')}`,
        message: `${c('errors.common.default.message')}`,
      });
    }
    setLoading(false);
  });
  return (
    <AuthCommons.AuthCommonContainer {...args}>
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText
              preset={TextSizes.heading5}
              weight={EFontWeight.medium}
              color={Colors.gray500}
              data-subtitle
            >
              {t('auth.signin.label', {})}
            </UI.VoxText>
            <div className='h-2' />
            <UI.VoxText
              preset={TextSizes.heading3}
              color={Colors.pink800}
              weight={EFontWeight.bold}
            >
              {t('auth.signin.title', {})}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInput
              data-testid='vox-signin-input-user'
              label=''
              // label={t('auth.signin.forms.input_user.label', {})}
              placeholder={t('auth.signin.forms.input_user.placeholder', {})}
              hasError={!!errors.userName}
              errorMessage={errors.userName?.message}
              isDisabled={isLoading}
              dark
              removeWhiteSpaces
              {...register('userName')}
            />
            <UI.VoxInput
              data-testid='vox-signin-input-password'
              label=''
              // label={t('auth.signin.forms.input_password.label', {})}
              placeholder={t(
                'auth.signin.forms.input_password.placeholder',
                {}
              )}
              isTexthidden
              hasError={!!errors.userPassword}
              errorMessage={errors.userPassword?.message}
              isDisabled={isLoading}
              dark
              {...register('userPassword')}
            />
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxButton
              data-testid='vox-recovery-event'
              label={t('auth.signin.forms.button_recovery.label', {})}
              preset={EButtonPreset.link}
              color={Colors.light}
              onClick={() => (isLoading ? {} : history.push('AuthRecovery'))}
              disabled={isLoading}
              underline
              isBlock
            />
            <UI.VoxButton
              data-testid='vox-signin-event'
              label={t('auth.signin.forms.button_send.label', {})}
              preset={EButtonPreset.solid}
              color={Colors.light}
              onClick={onSubmit}
              isLoading={isLoading}
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthSignIn;
