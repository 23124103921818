// Component generated with util/vox-create-component.js
import React from 'react';
import { AppContainerWrapper } from './AppContainer.styled';
import { AppContainerProps } from './AppContainer.types';
import { AppNavigation } from './../AppNavigation/AppNavigation';
import { AppFooter } from './../AppFooter/AppFooter';

export const AppContainer: React.FC<AppContainerProps> = ({
  children,
  ...args
}) => {
  const header = document.querySelector('#vox-header');
  return (
    <AppContainerWrapper {...args}>
      <AppNavigation />
      <div
        className='bg-light-100 w-full flex flex-col items-center'
        style={{
          minHeight: `calc(100vh - ${
            header?.getClientRects()[0].height || 0
          }px)`,
        }}
      >
        {children}
      </div>
      <AppFooter />
    </AppContainerWrapper>
  );
};

export default AppContainer;
