// Component generated with util/vox-create-component.js
import styled, { keyframes } from 'styled-components';

/**
 * Frames
 */
const rotateFrame = keyframes`
 from {
   transform: rotate(0deg);
 }
 to {
   transform: rotate(360deg);
 }
`;

/**
 * Components
 */
export const LoadingWrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.darkBlue}99;
  align-items: center;
  justify-content: center;
  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .loading {
      width: fit-content;
      height: fit-content;
      animation: ${rotateFrame} 1s linear infinite;
    }
    & > .message {
      text-align: center;
      width: 240px;
      margin-top: 4px;
    }
  }
`;
