// Component generated with util/vox-create-component.js
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthAccessTokenProps } from './AuthAccessToken.types';
import { AuthCommons } from './../../ui';
import { UI } from './../../component';
import {
  authConfirmSignInService,
  authSignInService,
} from './../../service/aws/auth';
import { useTranslation } from 'react-i18next';
import { commonDecryptAES } from './../../helpers';
import AuthContext from './../../context/AuthContext';
import AlertContext from './../../context/AlertContext';
import { EAlertType } from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';

export const AuthAccessToken: React.FC<AuthAccessTokenProps> = ({
  ...args
}) => {
  // Context
  const { setSession, resetUserName, userSecret, getProfile, getDependents } =
    React.useContext(AuthContext);
  // States
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [errorToken] = React.useState<string>('');
  const [tokenValue, setTokenValue] = React.useState();
  const [currentUser, setCurrentUser] = React.useState(resetUserName);
  const { showAlert } = React.useContext(AlertContext);
  // languages
  const [t] = useTranslation('auth');
  const [e] = useTranslation('errors');
  // History
  const history = useHistory();
  const { CODE_DELIVERY_DESTINATION } = useParams<{
    CODE_DELIVERY_DESTINATION: string;
  }>();
  // Redirect if user is not in the context
  // if (!currentUser && history) history.push('/AuthSignIn');
  // Handlers
  const onSubmit = async () => {
    setLoading(true);
    const { data: dataSignIn, error } = await authConfirmSignInService(
      currentUser,
      `${tokenValue}`,
      'SMS_MFA'
    );
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
      setLoading(false);
      return;
    }
    try {
      const profile: any = await getProfile({
        userName: currentUser.username,
        id: dataSignIn.signInUserSession.accessToken.payload.sub,
      });
      const profiles = await getDependents(`${profile?.personId}`);
      setSession({
        user: profile,
        profiles,
        aToken: dataSignIn.signInUserSession?.accessToken?.jwtToken,
        rToken: dataSignIn.signInUserSession?.refreshToken?.token,
      });
    } catch (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: t(`errors.common.default.message`),
      });
    }
    setLoading(false);
  };
  const requestAccessToken = async () => {
    setLoading(true);
    const accessKey = commonDecryptAES(userSecret, 'USER_KEY', 'STRING');
    const { data: dataSignIn, error } = await authSignInService(
      currentUser.username,
      accessKey
    );
    setLoading(false);
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
      return;
    }
    setCurrentUser(dataSignIn);
    showAlert({
      type: EAlertType.success,
      title: 'Novo código',
      message: error?.message || 'Um novo código foi enviado com sucesso',
    });
    setLoading(false);
  };
  const onTokenChange = (token: any) => {
    if (token) setTokenValue(token);
  };
  return (
    <AuthCommons.AuthCommonContainer {...args}>
      <AuthCommons.AuthCommonHeader>
        <UI.VoxButton
          preset={EButtonPreset.solid}
          color={Colors.dark200}
          onClick={() => !isLoading && history.goBack()}
        />
      </AuthCommons.AuthCommonHeader>
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText
              preset={TextSizes.body}
              color={Colors.gray800}
              weight={EFontWeight.medium}
            >
              {t('auth.accessToken.label')}
            </UI.VoxText>
            <UI.VoxText
              preset={TextSizes.heading2}
              color={Colors.light}
              weight={EFontWeight.bold}
            >
              {t('auth.accessToken.title')}
            </UI.VoxText>
            <UI.VoxText color={Colors.light} weight={EFontWeight.bold}>
              {t('auth.accessToken.description').replace(
                '@destination',
                CODE_DELIVERY_DESTINATION
              )}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInputBlocks
              size={6}
              label='Digite o token de acesso'
              hasError={!!errorToken && !isLoading}
              errorMessage={errorToken}
              onFillComplete={onTokenChange}
              isDisabled={isLoading}
              dark
            />
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxText
              color={Colors.cyan}
              preset={TextSizes.body}
              isLink
              onClick={requestAccessToken}
            >
              {t('auth.accessToken.forms.button_new_token.label', {})}
            </UI.VoxText>
            {/* <UI.VoxButton
							label={t('auth.accessToken.forms.button_send.label', {})}
							preset="solid"
							color="dark"
							onClick={requestAccessToken}
							isLoading={isLoading}
							isBlock
						/> */}
            <UI.VoxButton
              label={t('auth.accessToken.forms.button_send.label', {})}
              preset={EButtonPreset.outline}
              color={Colors.cyan}
              onClick={onSubmit}
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthAccessToken;
