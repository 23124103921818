// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AuthChooseAccountWrapper = styled.div``;

export const AuthAccountsList = styled.div`
  margin-top: -20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-height: calc(62px * 5.5);
    overflow: auto;
  }
`;

export const AuthAccount = styled.div<{ isSelected: boolean }>`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.cyan : theme.colors.gray800};
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 10px;
  > div {
    flex: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const AuthAccountActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AuthAccountPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -8px;
  margin-bottom: 10px;
  padding: 0 8px;
`;
