import React from 'react';
import Router from './../router';
import { ThemeProvider } from 'styled-components';
import { voxAppTheme } from './../theme/VoxAppTheme';
import { AuthProvider } from './../context/AuthContext';
import { AlertProvider } from './../context/AlertContext';
import { SensitiveProvider } from './../context/SensitiveContext';
import { AppProvider } from './../context/AppContext';
import { I18nextProvider } from 'react-i18next';
import { i18next } from './../translations';
import { ApolloProvider } from '@apollo/client';
import { client } from './../apollo/config';
import { AppCookie } from './../component';
import { getCookie } from './../helpers';
import { initDataDog } from './../analytics';

function App() {
  const cookiesAccepted = getCookie('voxcookie');
  initDataDog();
  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={voxAppTheme}>
          <AlertProvider>
            <AuthProvider>
              <AppProvider>
                <SensitiveProvider>
                  <Router />
                  {!cookiesAccepted && <AppCookie />}
                </SensitiveProvider>
              </AppProvider>
            </AuthProvider>
          </AlertProvider>
        </ThemeProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
}

export default App;
