// Component generated with util/vox-create-component.js
import React from 'react';
import { AppFooterProps } from './AppFooter.types';
import { VoxReferences } from '@vox-capital/vox-ui-package';

const footer = {
  title: '',
  description: '',
  links: [
    {
      label:
        'Rentabilidade passada não representa garantia de rentabilidade futura',
      description:
        'A Rentabilidade e o Patrimônio não são líquidos de impostos.',
      link: '',
    },
    {
      label:
        'Os Fundos de Investimento aqui apresentados não contam com garantia',
      description:
        'do administrador, do gestor, de qualquer mecanismo de seguro ou Fundo Garantidor de Crédito (FGC).',
      link: '',
    },
    {
      label: 'Leia o formulário de informações complementares,',
      description:
        'a lâmina de informações essenciais e o regulamento antes de investir.',
      link: '',
    },
  ],
  footer: {
    images: [
      'http://voxcapital.com.br/wp-content/uploads/2022/12/anbima.png',
      'http://voxcapital.com.br/wp-content/uploads/2022/12/anbima2.png',
    ],
    description:
      'As informações contidas neste material são de caráter meramente informativo e não constituem qualquer tipo de aconselhamento de investimentos, não devendo ser utilizadas com este propósito.',
    company: '2022 - 2023 VOX Capital',
  },
};

export const AppFooter: React.FC<AppFooterProps> = props => {
  return (
    <div className='bg-gray-200 w-full'>
      <VoxReferences {...footer} />
    </div>
  );
};

export default AppFooter;
