import { datadogRum } from '@datadog/browser-rum';
import { default as dataDogTags } from './tags';

/**
 * Inizializar data dog
 */
const initDataDog = () => {
  datadogRum.setGlobalContextProperty(`${process.env.REACT_APP_DD_SERVICE}`, {
    version: `${process.env.REACT_APP_DD_VERSION}`,
  });

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID!,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN!,
    site: process.env.REACT_APP_DD_SITE!,
    service: process.env.REACT_APP_DD_SERVICE!,
    env: process.env.REACT_APP_DD_ENV!,
    version: process.env.REACT_APP_DD_VERSION,
    // ⚠️ O Action Name é tambem utilizado no Design System, caso for alterado, será necessario alterar no Design System project
    actionNameAttribute: 'data-vox-action',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackViewsManually: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    beforeSend: (event, context: any) => {
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        console.log('Before send: ', event, context);
        event.context = {
          ...event.context,
          responseHeaders: context.response.headers,
        };
      }
    },
  });

  datadogRum.startSessionReplayRecording();
  datadogRum.getInternalContext();
};

/**
 * Track Action
 * Utilizada para trakear as ações do usuário
 * @param name Nome da ação
 * @param context Informações adicionais
 */
const trackAction = (name: string, context?: object | undefined) => {
  datadogRum.addAction(name, context);
};

/**
 * Track View
 * Utilizada para trackear as Páginas da plataforma
 * @param name Tag da View / Page (Nome da pagina)
 * @param version Versão de teste, por default está definida a mesma versão do dd version
 */
const trackView = (name: string, version?: string) => {
  datadogRum.startView({
    name,
    version: version ?? process.env.REACT_APP_DD_VERSION,
  });
};

/**
 * Track Error
 * Utilizada para trackear erros
 * @param error Tag do erro
 * @param content Informações adicionais do erro
 */
const trackError = (error: string, content: object | undefined) => {
  datadogRum.addError(error, content);
};

/**
 * Track Rest events
 * @param options Informações adicionais do request
 */
const trackRestFetch = (options: object) => {
  datadogRum.addRumGlobalContext(dataDogTags.fetch.request.rest, options);
};

/**
 * Tracj Graphql events
 * @param options Informações adicionais do request
 */
const trackGraphqlFetch = (options: object) => {
  datadogRum.addRumGlobalContext(dataDogTags.fetch.request.graphql, options);
};

const setUserSession = (pUser: any) => {
  datadogRum.setUser({
    tag: dataDogTags.session,
    ...pUser,
  });
};

const clearUSerSession = () => datadogRum.clearUser();

export {
  dataDogTags,
  setUserSession,
  initDataDog,
  trackAction,
  trackView,
  trackError,
  trackRestFetch,
  trackGraphqlFetch,
  clearUSerSession,
};
