// Component generated with util/vox-create-component.js
import styled from 'styled-components';
import { AuthContextProps } from './AuthContext.types';
import voxLogin from './../../assets/images/vox-sigin-img.png';

/**
 * Components
 */
export const AuthContextWrapper = styled.div<AuthContextProps>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 100vh;
    flex: 1.5;
  }
  /* background-image: url(${voxLogin});
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain; */
  /* justify-content: flex-end;
	align-items: flex-start; */
  /* width: 100%; */
  /* background-position: bottom;
	background-repeat: repeat-x; */
  /* background-size: 45%; */
  /* position: relative; */
  /* @media (max-width: 720px) {
		background-size: 0%;
	} */
`;

// export const AuthContextBackground = styled.div`
// 	position: absolute;
// 	background-color: ${({theme}) => theme.colors.ceramic};
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100vw;
// 	z-index: -1;
// 	@media (max-width: 720px) {
// 		display: none;
// 	}
// `;

export const AuthContextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  /* @media (max-width: 720px) {
		display: none;
	} */
`;

export const AuthContextHeader = styled.div`
  margin-bottom: 50px;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 0px;
  }
`;

export const AuthContextBody = styled.div`
  display: none;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  .title {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    margin: 40px 0;
    .title {
      width: 100%;
      display: flex;
      flex-direction: column;
      transform: translateY(-20px);
      span {
        font-size: 52px;
        line-height: 54px;
      }
    }
    video {
      display: none;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    background-position: left;
    flex: 1;
    .title {
      gap: 4px;
      span {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        display: block;
        width: fit-content;
        padding: 0 6px;
      }
    }
    video {
      display: block;
      width: 75%;
      margin: 0 auto;
      padding-bottom: 5rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
  }
`;
