// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AuthCommonContainer = styled.div.attrs({
  className: 'flex flex-col overflow-hidden flex-1 w-full',
})``;

export const AuthCommonHeader = styled.div.attrs({
  className: 'flex items-center w-full h-14 justify-between',
})``;

export const AuthCommonBody = styled.div.attrs({
  className: 'flex flex-1 justify-between md:items-center pb-4',
})``;

export const AuthCommonFooter = styled.div``;

export const AuthCommonFormContainer = styled.div.attrs({
  className: 'flex flex-col flex-1 gap-2',
})``;

export const AuthCommonFormHeader = styled.div.attrs({
  className: 'flex flex-col mb-4 md:mb-8',
})``;

export const AuthCommonFormBody = styled.div.attrs({
  className: 'flex flex-col gap-4 mt-4 mb-8 md:mb-16',
})``;
export const AuthCommonFormFooter = styled.div.attrs({
  className: 'flex justify-end gap-4',
})``;
