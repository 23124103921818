import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  accountId?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  wallet?: Maybe<Wallet>;
};

export type Address = {
  __typename?: 'Address';
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type Answers = {
  __typename?: 'Answers';
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  description?: Maybe<Scalars['String']>;
  funds?: Maybe<Array<Maybe<Fund>>>;
  identity: Scalars['String'];
  impacts?: Maybe<Impact>;
  name?: Maybe<Scalars['String']>;
  prospect?: Maybe<Scalars['String']>;
  risks?: Maybe<Scalars['String']>;
};

export type AvailableStatement = {
  __typename?: 'AvailableStatement';
  date?: Maybe<Scalars['String']>;
  fundsId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Document = {
  __typename?: 'Document';
  date?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FinancialSummary = {
  __typename?: 'FinancialSummary';
  historyData?: Maybe<HistoryData>;
  profitability?: Maybe<Scalars['Float']>;
  totalProfitability?: Maybe<Scalars['Float']>;
};

export type Fund = {
  __typename?: 'Fund';
  administrationFee?: Maybe<Scalars['Float']>;
  administrator?: Maybe<Scalars['String']>;
  applicationDelay?: Maybe<Scalars['Float']>;
  assetList?: Maybe<Array<Maybe<Scalars['String']>>>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  category?: Maybe<Scalars['String']>;
  comercialProfitability?: Maybe<Scalars['String']>;
  currentQuota?: Maybe<Quota>;
  custodian?: Maybe<Array<Maybe<Scalars['String']>>>;
  dayProfitability?: Maybe<Scalars['Float']>;
  documentList?: Maybe<Array<Maybe<Document>>>;
  expectation?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  historyData?: Maybe<HistoryData>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imagePath?: Maybe<Scalars['String']>;
  isProfessionalInvestors?: Maybe<Scalars['Boolean']>;
  isQualifiedInvestors?: Maybe<Scalars['Boolean']>;
  lastQuota?: Maybe<Quota>;
  lastYearProfitability?: Maybe<Scalars['Float']>;
  legalId?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  minAdditionalSubscription?: Maybe<Scalars['Float']>;
  minBalance?: Maybe<Scalars['Float']>;
  minSubscription?: Maybe<Scalars['Float']>;
  monthProfitability?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  netEquity?: Maybe<Scalars['Float']>;
  operations?: Maybe<Array<Maybe<Operation>>>;
  performanceFee?: Maybe<Scalars['Float']>;
  pl?: Maybe<Scalars['Float']>;
  profitTarget?: Maybe<Scalars['String']>;
  profitability?: Maybe<Scalars['Float']>;
  rescueDelay?: Maybe<Scalars['Float']>;
  risk?: Maybe<Scalars['String']>;
  sixMonthProfitability?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  taxation?: Maybe<Scalars['String']>;
  thirtySixMonthProfitability?: Maybe<Scalars['Float']>;
  threeMonthProfitability?: Maybe<Scalars['Float']>;
  totalProfitability?: Maybe<Scalars['Float']>;
  twelveMonthProfitability?: Maybe<Scalars['Float']>;
  twentyFourMonthProfitability?: Maybe<Scalars['Float']>;
};

export type HistoryData = {
  __typename?: 'HistoryData';
  amortization: Array<Maybe<Scalars['Float']>>;
  date: Array<Maybe<Scalars['Float']>>;
  integralization: Array<Maybe<Scalars['Float']>>;
  position: Array<Maybe<Scalars['Float']>>;
  profitability: Array<Maybe<Scalars['Float']>>;
  totalProfitability: Array<Maybe<Scalars['Float']>>;
};

export type Impact = {
  __typename?: 'Impact';
  assetId: Scalars['ID'];
  kaleidoscopeList?: Maybe<Array<Maybe<Kaleidoscope>>>;
  odsList?: Maybe<Array<Maybe<Ods>>>;
  thesisList?: Maybe<Array<Maybe<Thesis>>>;
};

export type Income = {
  __typename?: 'Income';
  application?: Maybe<Scalars['Float']>;
  monthlyBilling?: Maybe<Scalars['Float']>;
  property?: Maybe<Scalars['Float']>;
  resourceOrigin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncomeInput = {
  application?: Maybe<Scalars['Float']>;
  monthlyBilling?: Maybe<Scalars['Float']>;
  property?: Maybe<Scalars['Float']>;
  resourceOrigin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Individual = {
  __typename?: 'Individual';
  account?: Maybe<Account>;
  /** @deprecated cpf is deprecated. Use personaldata.cpf instead. */
  cpf?: Maybe<Scalars['String']>;
  /** @deprecated data_nascimento is deprecated. Use personaldata.birthDate instead. */
  data_nascimento?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated fullName is deprecated. Use personaldata.fullName instead. */
  fullName?: Maybe<Scalars['String']>;
  income?: Maybe<Income>;
  occupation?: Maybe<Occupation>;
  personId?: Maybe<Scalars['String']>;
  personaldata?: Maybe<PersonalData>;
  portfolio?: Maybe<Portfolio>;
  /** @deprecated relacionamento is deprecated. */
  relacionamento?: Maybe<Scalars['String']>;
  /** @deprecated socialName is deprecated. Use personaldata.socialName instead. */
  socialName?: Maybe<Scalars['String']>;
  suitability?: Maybe<Suitability>;
  username?: Maybe<Scalars['String']>;
};

export type Kaleidoscope = {
  __typename?: 'Kaleidoscope';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageId?: Maybe<Array<Maybe<Scalars['String']>>>;
  imagePath?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  videoId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTerm?: Maybe<Scalars['String']>;
  onboarding?: Maybe<Onboarding>;
  saveTrustDevice?: Maybe<Scalars['String']>;
  sendVerifyCodePhone?: Maybe<Scalars['String']>;
};

export type MutationAcceptTermArgs = {
  loginId: Scalars['ID'];
};

export type MutationOnboardingArgs = {
  signupInput?: Maybe<SignupInput>;
};

export type MutationSaveTrustDeviceArgs = {
  loginId: Scalars['ID'];
};

export type MutationSendVerifyCodePhoneArgs = {
  loginId: Scalars['ID'];
  phone: Scalars['String'];
};

export type Ods = {
  __typename?: 'ODS';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Occupation = {
  __typename?: 'Occupation';
  cnpjCompany?: Maybe<Scalars['String']>;
  mainOccupation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameCompany?: Maybe<Scalars['String']>;
};

export type OccupationInput = {
  cnpjCompany?: Maybe<Scalars['String']>;
  mainOccupation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameCompany?: Maybe<Scalars['String']>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  status?: Maybe<Scalars['String']>;
  suitability?: Maybe<Scalars['String']>;
};

export type Operation = {
  __typename?: 'Operation';
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grossValue?: Maybe<Scalars['Float']>;
  iof?: Maybe<Scalars['Float']>;
  ir?: Maybe<Scalars['Float']>;
  netValue?: Maybe<Scalars['Float']>;
  operationId?: Maybe<Scalars['String']>;
  personId: Scalars['ID'];
  quotaBalance?: Maybe<Scalars['Float']>;
  quotaQuantity?: Maybe<Scalars['Float']>;
  quotaValue?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type PersonalData = {
  __typename?: 'PersonalData';
  address?: Maybe<Address>;
  birthDate?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  motherName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Array<Maybe<Scalars['String']>>>;
  naturalnessCity?: Maybe<Scalars['String']>;
  naturalnessState?: Maybe<Scalars['String']>;
  partnerCpf?: Maybe<Scalars['String']>;
  socialName?: Maybe<Scalars['String']>;
  usPerson?: Maybe<Scalars['Boolean']>;
};

export type PersonalDataInput = {
  address?: Maybe<AddressInput>;
  birthDate?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  motherName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Array<Maybe<Scalars['String']>>>;
  naturalnessCity?: Maybe<Scalars['String']>;
  naturalnessState?: Maybe<Scalars['String']>;
  partnerCpf?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
  usPerson?: Maybe<Scalars['Boolean']>;
};

export type PersonalDocumentInput = {
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<TypeFiles>;
};

export type PhoneInput = {
  number?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  financialSummary?: Maybe<FinancialSummary>;
  personId?: Maybe<Scalars['String']>;
  positions: Array<Maybe<Position>>;
};

export type Position = {
  __typename?: 'Position';
  currentPositionValue?: Maybe<Scalars['Float']>;
  profitability?: Maybe<Scalars['Float']>;
  totalProfitability?: Maybe<Scalars['Float']>;
  documentList?: Maybe<Array<Maybe<Document>>>;
  fund?: Maybe<Fund>;
  historyData?: Maybe<HistoryData>;
  integralizationValue?: Maybe<Scalars['Float']>;
  personId: Scalars['ID'];
  profitability?: Maybe<Scalars['Float']>;
  subscriptionValue?: Maybe<Scalars['Float']>;
  totalProfitability?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  availableStatements?: Maybe<Array<Maybe<AvailableStatement>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  funds?: Maybe<Array<Maybe<Fund>>>;
  getSDKTokens?: Maybe<SdkTokens>;
  impacts?: Maybe<Impact>;
  individual?: Maybe<Individual>;
  kaleidoscope?: Maybe<Array<Maybe<Kaleidoscope>>>;
  myInfo?: Maybe<User>;
  portfolio?: Maybe<Portfolio>;
  profiles?: Maybe<Array<Maybe<Individual>>>;
  statements?: Maybe<Array<Maybe<Statement>>>;
};

export type QueryAvailableStatementsArgs = {
  endDate?: Maybe<Scalars['String']>;
  intervalInMonths?: Maybe<Scalars['Int']>;
  personId: Scalars['ID'];
};

export type QueryDocumentsArgs = {
  path?: Maybe<Scalars['String']>;
};

export type QueryFundsArgs = {
  fundId?: Maybe<Scalars['ID']>;
};

export type QueryImpactsArgs = {
  assetId?: Maybe<Scalars['ID']>;
};

export type QueryIndividualArgs = {
  personId: Scalars['String'];
};

export type QueryMyInfoArgs = {
  loginId: Scalars['ID'];
};

export type QueryPortfolioArgs = {
  personId: Scalars['ID'];
};

export type QueryProfilesArgs = {
  personId: Scalars['ID'];
};

export type QueryStatementsArgs = {
  statementInput?: Maybe<StatementInput>;
};

export type QuestionAnswerInput = {
  answerId: Array<Maybe<Scalars['Int']>>;
  questionId: Scalars['Int'];
};

export type QuestionsAnswers = {
  __typename?: 'QuestionsAnswers';
  answerId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  answers?: Maybe<Array<Maybe<Answers>>>;
  question?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  weigth?: Maybe<Scalars['Int']>;
};

export type Quota = {
  __typename?: 'Quota';
  date?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type SdkTokens = {
  __typename?: 'SDKTokens';
  documentDetectorToken?: Maybe<Scalars['String']>;
  faceAuthenticatorToken?: Maybe<Scalars['String']>;
  passiveFaceLivenessToken?: Maybe<Scalars['String']>;
};

export type SignupInput = {
  documentList?: Maybe<Array<Maybe<PersonalDocumentInput>>>;
  income?: Maybe<IncomeInput>;
  occupation?: Maybe<OccupationInput>;
  personId: Scalars['String'];
  personaldata?: Maybe<PersonalDataInput>;
  suitability?: Maybe<SuitabilityInput>;
};

export type Statement = {
  __typename?: 'Statement';
  clientId?: Maybe<Scalars['String']>;
  cpfCnpj?: Maybe<Scalars['String']>;
  currentQuota?: Maybe<Quota>;
  date?: Maybe<Scalars['String']>;
  dayProfitability?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  fundId?: Maybe<Scalars['String']>;
  lastQuota?: Maybe<Quota>;
  lastYearProfitability?: Maybe<Scalars['Float']>;
  monthProfitability?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  netEquity?: Maybe<Scalars['Float']>;
  operations?: Maybe<Array<Maybe<Operation>>>;
  personId?: Maybe<Scalars['String']>;
  profitability?: Maybe<Scalars['Float']>;
  sixMonthProfitability?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  thirtySixMonthProfitability?: Maybe<Scalars['Float']>;
  threeMonthProfitability?: Maybe<Scalars['Float']>;
  totalProfitability?: Maybe<Scalars['Float']>;
  twelveMonthProfitability?: Maybe<Scalars['Float']>;
  twentyFourMonthProfitability?: Maybe<Scalars['Float']>;
};

export type StatementInput = {
  date?: Maybe<Scalars['String']>;
  fundsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  personId: Scalars['ID'];
};

export type Suitability = {
  __typename?: 'Suitability';
  application?: Maybe<Scalars['Int']>;
  formId?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['Int']>;
  questionsAnswers?: Maybe<Array<Maybe<QuestionsAnswers>>>;
};

export type SuitabilityInput = {
  formId: Scalars['String'];
  questionsAnswers: Array<Maybe<QuestionAnswerInput>>;
};

export type Theme = {
  __typename?: 'Theme';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Thesis = {
  __typename?: 'Thesis';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  themeList?: Maybe<Array<Maybe<Theme>>>;
  title?: Maybe<Scalars['String']>;
};

export enum TypeFiles {
  CnhBack = 'CNH_BACK',
  CnhFront = 'CNH_FRONT',
  Crlv = 'CRLV',
  RgBack = 'RG_BACK',
  RgFront = 'RG_FRONT',
  Selfie = 'SELFIE',
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  individual?: Maybe<Individual>;
  personId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  amount?: Maybe<Scalars['Float']>;
};

export type AcceptTermMutationVariables = Exact<{
  loginId: Scalars['ID'];
}>;

export type AcceptTermMutation = {
  __typename?: 'Mutation';
  acceptTerm?: string | null | undefined;
};

export type GetDocumentsQueryVariables = Exact<{
  personId: Scalars['ID'];
}>;

export type GetDocumentsQuery = {
  __typename?: 'Query';
  portfolio?:
    | {
        __typename?: 'Portfolio';
        positions: Array<
          | {
              __typename?: 'Position';
              fund?:
                | {
                    __typename?: 'Fund';
                    name?: string | null | undefined;
                    id: string;
                    documentList?:
                      | Array<
                          | {
                              __typename?: 'Document';
                              date?: string | null | undefined;
                              id: string;
                              name?: string | null | undefined;
                              type?: string | null | undefined;
                              highlights?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetPositionQueryVariables = Exact<{
  personId: Scalars['ID'];
}>;

export type GetPositionQuery = {
  __typename?: 'Query';
  portfolio?:
    | {
        __typename?: 'Portfolio';
        positions: Array<
          | {
              __typename?: 'Position';
              personId: string;
              subscriptionValue?: number | null | undefined;
              integralizationValue?: number | null | undefined;
              currentPositionValue?: number | null | undefined;
              profitability?: number | null | undefined;
              totalProfitability?: number | null | undefined;
              fund?:
                | {
                    __typename?: 'Fund';
                    name?: string | null | undefined;
                    id: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetPortafolioQueryVariables = Exact<{
  personId: Scalars['ID'];
}>;

export type GetPortafolioQuery = {
  __typename?: 'Query';
  portfolio?:
    | {
        __typename?: 'Portfolio';
        financialSummary?:
          | {
              __typename?: 'FinancialSummary';
              profitability?: number | null | undefined;
              totalProfitability?: number | null | undefined;
              historyData?:
                | {
                    __typename?: 'HistoryData';
                    date: Array<number | null | undefined>;
                    position: Array<number | null | undefined>;
                    integralization: Array<number | null | undefined>;
                    profitability: Array<number | null | undefined>;
                    amortization: Array<number | null | undefined>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        positions: Array<
          | {
              __typename?: 'Position';
              subscriptionValue?: number | null | undefined;
              integralizationValue?: number | null | undefined;
              currentPositionValue?: number | null | undefined;
              profitability?: number | null | undefined;
              totalProfitability?: number | null | undefined;
              fund?:
                | {
                    __typename?: 'Fund';
                    name?: string | null | undefined;
                    id: string;
                    profitability?: number | null | undefined;
                    totalProfitability?: number | null | undefined;
                    assets?:
                      | Array<
                          | {
                              __typename?: 'Asset';
                              description?: string | null | undefined;
                              identity: string;
                              name?: string | null | undefined;
                              prospect?: string | null | undefined;
                              impacts?:
                                | {
                                    __typename?: 'Impact';
                                    kaleidoscopeList?:
                                      | Array<
                                          | {
                                              __typename?: 'Kaleidoscope';
                                              id: string;
                                              imageId?:
                                                | Array<
                                                    string | null | undefined
                                                  >
                                                | null
                                                | undefined;
                                              subtitle?:
                                                | string
                                                | null
                                                | undefined;
                                              text?: string | null | undefined;
                                              title?: string | null | undefined;
                                              type?: string | null | undefined;
                                              videoId?:
                                                | Array<
                                                    string | null | undefined
                                                  >
                                                | null
                                                | undefined;
                                              createdAt?:
                                                | string
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    documentList?:
                      | Array<
                          | {
                              __typename?: 'Document';
                              date?: string | null | undefined;
                              id: string;
                              name?: string | null | undefined;
                              type?: string | null | undefined;
                              highlights?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type MyProfileQueryVariables = Exact<{
  loginId: Scalars['ID'];
}>;

export type MyProfileQuery = {
  __typename?: 'Query';
  myInfo?:
    | {
        __typename?: 'User';
        id: string;
        personId?: string | null | undefined;
        username?: string | null | undefined;
        version?: string | null | undefined;
        individual?:
          | {
              __typename?: 'Individual';
              username?: string | null | undefined;
              socialName?: string | null | undefined;
              fullName?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetProfileQueryVariables = Exact<{
  personId: Scalars['ID'];
}>;

export type GetProfileQuery = {
  __typename?: 'Query';
  profiles?:
    | Array<
        | {
            __typename?: 'Individual';
            cpf?: string | null | undefined;
            data_nascimento?: string | null | undefined;
            email?: string | null | undefined;
            socialName?: string | null | undefined;
            fullName?: string | null | undefined;
            personId?: string | null | undefined;
            relacionamento?: string | null | undefined;
            username?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetAvailableStatementsQueryVariables = Exact<{
  intervalInMonths: Scalars['Int'];
  personId: Scalars['ID'];
}>;

export type GetAvailableStatementsQuery = {
  __typename?: 'Query';
  availableStatements?:
    | Array<
        | {
            __typename?: 'AvailableStatement';
            date?: string | null | undefined;
            fundsId?: Array<string | null | undefined> | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetStatementsQueryVariables = Exact<{
  personId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  fundsId?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetStatementsQuery = {
  __typename?: 'Query';
  statements?:
    | Array<
        | {
            __typename?: 'Statement';
            dayProfitability?: number | null | undefined;
            monthProfitability?: number | null | undefined;
            threeMonthProfitability?: number | null | undefined;
            sixMonthProfitability?: number | null | undefined;
            twelveMonthProfitability?: number | null | undefined;
            twentyFourMonthProfitability?: number | null | undefined;
            lastYearProfitability?: number | null | undefined;
            thirtySixMonthProfitability?: number | null | undefined;
            totalProfitability?: number | null | undefined;
            name?: string | null | undefined;
            netEquity?: number | null | undefined;
            startDate?: string | null | undefined;
            currentQuota?:
              | {
                  __typename?: 'Quota';
                  date?: string | null | undefined;
                  pl?: number | null | undefined;
                  value?: number | null | undefined;
                }
              | null
              | undefined;
            lastQuota?:
              | {
                  __typename?: 'Quota';
                  value?: number | null | undefined;
                  date?: string | null | undefined;
                  pl?: number | null | undefined;
                }
              | null
              | undefined;
            operations?:
              | Array<
                  | {
                      __typename?: 'Operation';
                      netValue?: number | null | undefined;
                      description?: string | null | undefined;
                      grossValue?: number | null | undefined;
                      date?: string | null | undefined;
                      iof?: number | null | undefined;
                      ir?: number | null | undefined;
                      tax?: number | null | undefined;
                      operationId?: string | null | undefined;
                      quotaQuantity?: number | null | undefined;
                      quotaValue?: number | null | undefined;
                      quotaBalance?: number | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetVoxDocumentsQueryVariables = Exact<{
  path?: Maybe<Scalars['String']>;
}>;

export type GetVoxDocumentsQuery = {
  __typename?: 'Query';
  documents?:
    | Array<
        | {
            __typename?: 'Document';
            id: string;
            name?: string | null | undefined;
            date?: string | null | undefined;
            type?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const AcceptTermDocument = gql`
  mutation acceptTerm($loginId: ID!) {
    acceptTerm(loginId: $loginId)
  }
`;
export type AcceptTermMutationFn = Apollo.MutationFunction<
  AcceptTermMutation,
  AcceptTermMutationVariables
>;

/**
 * __useAcceptTermMutation__
 *
 * To run a mutation, you first call `useAcceptTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermMutation, { data, loading, error }] = useAcceptTermMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useAcceptTermMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermMutation,
    AcceptTermMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptTermMutation, AcceptTermMutationVariables>(
    AcceptTermDocument,
    options
  );
}
export type AcceptTermMutationHookResult = ReturnType<
  typeof useAcceptTermMutation
>;
export type AcceptTermMutationResult =
  Apollo.MutationResult<AcceptTermMutation>;
export type AcceptTermMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermMutation,
  AcceptTermMutationVariables
>;
export const GetDocumentsDocument = gql`
  query getDocuments($personId: ID!) {
    portfolio(personId: $personId) {
      positions {
        fund {
          name
          id
          documentList {
            date
            id
            name
            type
            highlights
          }
        }
      }
    }
  }
`;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentsQuery,
    GetDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(
    GetDocumentsDocument,
    options
  );
}
export function useGetDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentsQuery,
    GetDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(
    GetDocumentsDocument,
    options
  );
}
export type GetDocumentsQueryHookResult = ReturnType<
  typeof useGetDocumentsQuery
>;
export type GetDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetDocumentsLazyQuery
>;
export type GetDocumentsQueryResult = Apollo.QueryResult<
  GetDocumentsQuery,
  GetDocumentsQueryVariables
>;
export const GetPositionDocument = gql`
  query getPosition($personId: ID!) {
    portfolio(personId: $personId) {
      positions {
        fund {
          name
          id
        }
        personId
        subscriptionValue
        integralizationValue
        currentPositionValue
        profitability
        totalProfitability
      }
    }
  }
`;

/**
 * __useGetPositionQuery__
 *
 * To run a query within a React component, call `useGetPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPositionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPositionQuery,
    GetPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPositionQuery, GetPositionQueryVariables>(
    GetPositionDocument,
    options
  );
}
export function useGetPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionQuery,
    GetPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPositionQuery, GetPositionQueryVariables>(
    GetPositionDocument,
    options
  );
}
export type GetPositionQueryHookResult = ReturnType<typeof useGetPositionQuery>;
export type GetPositionLazyQueryHookResult = ReturnType<
  typeof useGetPositionLazyQuery
>;
export type GetPositionQueryResult = Apollo.QueryResult<
  GetPositionQuery,
  GetPositionQueryVariables
>;
export const GetPortafolioDocument = gql`
  query getPortafolio($personId: ID!) {
    portfolio(personId: $personId) {
      financialSummary {
        profitability
        totalProfitability
        historyData {
          date
          position
          integralization
          profitability
          amortization
        }
      }
      positions {
        fund {
          name
          id
          profitability
          totalProfitability
          profitability
          assets {
            description
            identity
            name
            prospect
            impacts {
              kaleidoscopeList {
                id
                imageId
                subtitle
                text
                title
                type
                videoId
                createdAt
              }
            }
          }
          documentList {
            date
            id
            name
            type
            highlights
          }
        }
        subscriptionValue
        integralizationValue
        currentPositionValue
        profitability
        totalProfitability
      }
    }
  }
`;

/**
 * __useGetPortafolioQuery__
 *
 * To run a query within a React component, call `useGetPortafolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortafolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortafolioQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPortafolioQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortafolioQuery,
    GetPortafolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortafolioQuery, GetPortafolioQueryVariables>(
    GetPortafolioDocument,
    options
  );
}
export function useGetPortafolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortafolioQuery,
    GetPortafolioQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortafolioQuery, GetPortafolioQueryVariables>(
    GetPortafolioDocument,
    options
  );
}
export type GetPortafolioQueryHookResult = ReturnType<
  typeof useGetPortafolioQuery
>;
export type GetPortafolioLazyQueryHookResult = ReturnType<
  typeof useGetPortafolioLazyQuery
>;
export type GetPortafolioQueryResult = Apollo.QueryResult<
  GetPortafolioQuery,
  GetPortafolioQueryVariables
>;
export const MyProfileDocument = gql`
  query myProfile($loginId: ID!) {
    myInfo(loginId: $loginId) {
      id
      personId
      username
      version
      individual {
        username
        socialName
        fullName
        email
      }
    }
  }
`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useMyProfileQuery(
  baseOptions: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options
  );
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options
  );
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<
  typeof useMyProfileLazyQuery
>;
export type MyProfileQueryResult = Apollo.QueryResult<
  MyProfileQuery,
  MyProfileQueryVariables
>;
export const GetProfileDocument = gql`
  query getProfile($personId: ID!) {
    profiles(personId: $personId) {
      cpf
      data_nascimento
      email
      socialName
      fullName
      personId
      relacionamento
      username
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const GetAvailableStatementsDocument = gql`
  query getAvailableStatements($intervalInMonths: Int!, $personId: ID!) {
    availableStatements(
      intervalInMonths: $intervalInMonths
      personId: $personId
    ) {
      date
      fundsId
    }
  }
`;

/**
 * __useGetAvailableStatementsQuery__
 *
 * To run a query within a React component, call `useGetAvailableStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableStatementsQuery({
 *   variables: {
 *      intervalInMonths: // value for 'intervalInMonths'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetAvailableStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableStatementsQuery,
    GetAvailableStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableStatementsQuery,
    GetAvailableStatementsQueryVariables
  >(GetAvailableStatementsDocument, options);
}
export function useGetAvailableStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableStatementsQuery,
    GetAvailableStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableStatementsQuery,
    GetAvailableStatementsQueryVariables
  >(GetAvailableStatementsDocument, options);
}
export type GetAvailableStatementsQueryHookResult = ReturnType<
  typeof useGetAvailableStatementsQuery
>;
export type GetAvailableStatementsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableStatementsLazyQuery
>;
export type GetAvailableStatementsQueryResult = Apollo.QueryResult<
  GetAvailableStatementsQuery,
  GetAvailableStatementsQueryVariables
>;
export const GetStatementsDocument = gql`
  query getStatements($personId: ID!, $date: String, $fundsId: [String]) {
    statements(
      statementInput: { personId: $personId, date: $date, fundsId: $fundsId }
    ) {
      currentQuota {
        date
        pl
        value
      }
      dayProfitability
      lastQuota {
        value
        date
        pl
      }
      monthProfitability
      threeMonthProfitability
      sixMonthProfitability
      twelveMonthProfitability
      twentyFourMonthProfitability
      lastYearProfitability
      thirtySixMonthProfitability
      totalProfitability
      name
      netEquity
      operations {
        netValue
        description
        grossValue
        date
        iof
        ir
        tax
        operationId
        quotaQuantity
        quotaValue
        quotaBalance
      }
      startDate
    }
  }
`;

/**
 * __useGetStatementsQuery__
 *
 * To run a query within a React component, call `useGetStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      date: // value for 'date'
 *      fundsId: // value for 'fundsId'
 *   },
 * });
 */
export function useGetStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStatementsQuery,
    GetStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatementsQuery, GetStatementsQueryVariables>(
    GetStatementsDocument,
    options
  );
}
export function useGetStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatementsQuery,
    GetStatementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatementsQuery, GetStatementsQueryVariables>(
    GetStatementsDocument,
    options
  );
}
export type GetStatementsQueryHookResult = ReturnType<
  typeof useGetStatementsQuery
>;
export type GetStatementsLazyQueryHookResult = ReturnType<
  typeof useGetStatementsLazyQuery
>;
export type GetStatementsQueryResult = Apollo.QueryResult<
  GetStatementsQuery,
  GetStatementsQueryVariables
>;
export const GetVoxDocumentsDocument = gql`
  query getVoxDocuments($path: String) {
    documents(path: $path) {
      id
      name
      date
      type
    }
  }
`;

/**
 * __useGetVoxDocumentsQuery__
 *
 * To run a query within a React component, call `useGetVoxDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoxDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoxDocumentsQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetVoxDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVoxDocumentsQuery,
    GetVoxDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVoxDocumentsQuery, GetVoxDocumentsQueryVariables>(
    GetVoxDocumentsDocument,
    options
  );
}
export function useGetVoxDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVoxDocumentsQuery,
    GetVoxDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVoxDocumentsQuery,
    GetVoxDocumentsQueryVariables
  >(GetVoxDocumentsDocument, options);
}
export type GetVoxDocumentsQueryHookResult = ReturnType<
  typeof useGetVoxDocumentsQuery
>;
export type GetVoxDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetVoxDocumentsLazyQuery
>;
export type GetVoxDocumentsQueryResult = Apollo.QueryResult<
  GetVoxDocumentsQuery,
  GetVoxDocumentsQueryVariables
>;
