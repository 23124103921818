// Component generated with util/vox-create-component.js
import styled from 'styled-components';
import { AppHeaderProps } from './AppHeader.types';

/**
 * Components
 */
export const AppHeaderWrapper = styled.div<AppHeaderProps>`
  @media (max-width: 1240px) {
    justify-content: space-between;
  }
  /* .total {
		.profitability {
			span {
				color: ${({ theme }) => theme.colors.cyan};
			}
		}
	} */
  & .menu-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  & .menu-mobile {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    border-radius: 4px;
    > li {
      padding: 1rem;
      cursor: pointer;
      .menu-mobile-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
      }
      :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.gray200};
      }
    }
  }
`;
