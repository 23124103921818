// Component generated with util/vox-create-component.js
import React from 'react';
import { LoaderWrapper, LoadingAssets } from './Loader.styled';
import { LoaderProps } from './Loader.types';
import { UI } from './../../component';
import {
  Colors,
  EFontWeight,
  TextSizes,
  VoxLoadingIcon,
} from '@vox-capital/vox-ui-package';

export const Loader: React.FC<LoaderProps> = ({ message, ...args }) => {
  return (
    <LoaderWrapper {...args}>
      <LoadingAssets>
        <div role='status'>
          <VoxLoadingIcon color={Colors.pink800} />
        </div>
        <UI.VoxText preset={TextSizes.body} weight={EFontWeight.bold}>
          {message || 'Carregando'}
        </UI.VoxText>
      </LoadingAssets>
    </LoaderWrapper>
  );
};

export default Loader;
