// Component generated with util/vox-create-component.js
import React from 'react';
import { AppContactWrapper } from './AppContact.styled';
import { AppContactProps, ContactFormData } from './AppContact.types';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { string, SchemaOf, object } from 'yup';
import { UI } from './../../component';

export const AppContact: React.FC<AppContactProps> = ({
  toggleContact,
  ...args
}) => {
  // languages
  const [t] = useTranslation('contact');
  // Validations
  const ContactFormValidation: SchemaOf<ContactFormData> = object().shape({
    // subject: string().required(t('auth.signin.forms.input_user.error')),
    message: string().required(t('auth.signin.forms.input_password.error')),
  });
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>({
    resolver: yupResolver(ContactFormValidation),
    mode: 'onSubmit',
    defaultValues: {},
  });
  // Handlers
  const onSubmit = handleSubmit(async ({ message }) => {
    alert(`Send contact - Message: ${message}`);
  });
  return (
    <AppContactWrapper {...args}>
      <UI.VoxModal
        subTitle={t('contact.label')}
        title={t('contact.title')}
        closeModalAction={toggleContact}
        hasCancelButton
        primaryActionLabel='Enviar'
        primaryAction={onSubmit}
      >
        {/* <UI.VoxInput
					label={t('contact.forms.input_subject.label')}
					placeholder={t('contact.forms.input_subject.placeholder')}
					hasError={!!errors.subject}
					errorMessage={t('contact.forms.input_subject.error')}
					{...register('subject')}
				/> */}
        <UI.VoxTextArea
          label={t('contact.forms.input_message.label')}
          placeholder={t('contact.forms.input_message.placeholder')}
          hasError={!!errors.message}
          errorMessage={t('contact.forms.input_message.error')}
          hasHelp
          helpMessage={t('contact.forms.input_message.help').replace(
            '@count',
            '200'
          )}
          {...register('message')}
        />
      </UI.VoxModal>
    </AppContactWrapper>
  );
};

export default AppContact;
