// Component generated with util/vox-create-component.js
import * as R from 'ramda';
import React from 'react';
import { AppImpactDetailWrapper } from './AppImpactDetail.styled';
import { AppImpactDetailProps } from './AppImpactDetail.types';
import { UI } from './../';
import uniqid from 'uniqid';
import Player from '@vimeo/player';
import {
  Colors,
  EFontWeight,
  EModalMode,
  TextSizes,
} from '@vox-capital/vox-ui-package';

const ImpactVideo: React.FC<{ video: string; title: string }> = ({
  video,
  title,
}) => {
  // Ref
  const ref = React.useRef<HTMLIFrameElement>(null);
  // States
  const [height, setHeight] = React.useState(400);
  // Effect
  React.useEffect(() => {
    (async () => {
      if (ref && ref.current && video) {
        setHeight(ref.current.clientWidth * 0.56);
        const player = new Player(ref.current, {
          url: video,
          responsive: true,
          portrait: true,
          byline: false,
          loop: true,
        });
        await player.setLoop(true);
        player.on('play', onVideoPlay);
        player.on('ended', onVideoEnded);
      }
    })();
  }, [ref, video]);
  if (!video) return <></>;
  // Handlers
  const onVideoPlay = (data: any) => {};
  const onVideoEnded = (data: any) => {};
  return (
    <div className='video'>
      <div
        className='frame'
        ref={ref}
        style={{
          width: '100%',
          height,
        }}
      />
    </div>
  );
};

const ImpactImages: React.FC<{ image: string }> = ({ image }) => {
  // Ref
  const ref = React.useRef<HTMLDivElement>(null);
  // States
  const [height, setHeight] = React.useState(400);
  // Effect
  React.useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientWidth);
    }
  }, [ref]);
  // Component
  if (!image) return <></>;
  return (
    <div
      className='img'
      ref={ref}
      style={{
        height,
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
      }}
    />
  );
};

export const AppImpactDetail: React.FC<AppImpactDetailProps> = ({
  impactData,
  onCloseDetail,
  ...args
}) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  console.log('impactData: ', impactData);
  return (
    <UI.VoxModal
      title={impactData.title || ''}
      subTitle={impactData.subtitle || ''}
      mode={EModalMode.FULL}
      closeModalAction={onCloseDetail}
      data-testid='vox-impact-detail'
      data-id='vox-impact-detail'
      primaryActionLabel='Fechar'
      primaryAction={onCloseDetail}
      {...args}
    >
      <AppImpactDetailWrapper ref={modalRef}>
        <div className='header'>
          <div className='w-full md:w-8/12 mx-auto'>
            <UI.VoxText
              preset={TextSizes.bodyLg}
              weight={EFontWeight.medium}
              className='formattext'
              role={'textbox'}
              id=''
            >
              {impactData.text}
            </UI.VoxText>
          </div>
        </div>
        <div className='flex flex-col gap-8 py-8 px-8 bg-gray-100 rounded-md'>
          {impactData.videoId && !R.isEmpty(impactData.videoId) && (
            <div className='flex flex-col gap-4'>
              <UI.VoxText
                color={Colors.pink800}
                preset={TextSizes.heading5}
                weight={EFontWeight.bold}
              >
                Videos
              </UI.VoxText>
              <div className=''>
                {impactData.videoId.map(vid => (
                  <ImpactVideo
                    key={uniqid()}
                    title={impactData.title || ''}
                    video={vid || ''}
                  />
                ))}
              </div>
            </div>
          )}
          {impactData.imageId && !R.isEmpty(impactData.imageId) && (
            <div className='flex flex-col gap-4'>
              <UI.VoxText
                color={Colors.pink800}
                preset={TextSizes.heading5}
                weight={EFontWeight.bold}
              >
                Images
              </UI.VoxText>
              <div className='grid grid-cols-1 gap-8 content'>
                {impactData.imageId.map(img => (
                  <ImpactImages image={img || ''} key={uniqid()} />
                ))}
              </div>
            </div>
          )}
        </div>
      </AppImpactDetailWrapper>
    </UI.VoxModal>
  );
};

export default AppImpactDetail;
