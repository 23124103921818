// Component generated with util/vox-create-component.js
import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import { AppExtractsWrapper, EmptyData } from './AppExtracts.styled';
import uniqid from 'uniqid';
import { client } from './../../apollo/config';
import { AppExtractsProps } from './AppExtracts.types';
import { getMonthName, printStatement, saveDocument } from './../../helpers';
import { UI } from './../../component';
import { Loading } from './../../ui';
import { useTranslation } from 'react-i18next';
import {
  Fund,
  Statement,
  useGetAvailableStatementsLazyQuery,
  GetStatementsDocument,
} from './../../graphql/types.d';
import { AuthContext } from './../../context/AuthContext';
import { AlertContext } from './../../context/AlertContext';
import { useAnimation } from 'framer-motion';
import { Loader } from './../../ui';
import * as Images from './../../theme/images';
import {
  EAlertType,
  EFontWeight,
  Icons,
  VoxButton,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  ECardType,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import {
  dataDogTags,
  trackAction,
  trackError,
  trackView,
} from '../../analytics';

const getCardsPerPage = () => {
  const windowWidth = window.innerWidth;
  return windowWidth > 1824
    ? 6
    : windowWidth > 1444
    ? 5
    : windowWidth > 1040
    ? 4
    : windowWidth > 740
    ? 3
    : 0;
};

export const AppExtracts: React.FC<AppExtractsProps> = ({ ...args }) => {
  trackView(
    dataDogTags.views.privateStatements,
    dataDogTags.views.privateStatements
  );
  // Refs
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  // Animation
  const cardAnimation = useAnimation();
  // States
  const [scroll, setScroll] = React.useState(1);
  // graphql hooks
  const [getStatements, { data, error, loading, refetch, networkStatus }] =
    useGetAvailableStatementsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  // Translation
  const [s] = useTranslation('statements');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // Context
  const { user, clearSession } = React.useContext(AuthContext);
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  // States
  const [statements, setStatements] = React.useState<any>([]);
  const [load, setLoad] = React.useState(false);
  const [monthSelected, setMonth] = React.useState<any>();
  // Effects
  React.useEffect(() => {
    if (scrollRef && scrollRef.current && cardRef && cardRef.current) {
      const childsNodes = scrollRef.current.childNodes;
      const cardsPerPage = getCardsPerPage();
      const maxScroll = Math.ceil(childsNodes.length / cardsPerPage);
      if (scroll > maxScroll) {
        setScroll(scroll - 1);
        // setNext(false);
        return;
      } else {
        // setNext(true);
        const containerWith = scrollRef.current.offsetWidth;
        const x = containerWith * -(scroll - 1);
        cardAnimation.set({
          x,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);
  React.useEffect(() => {
    if (R.isEmpty(statements)) {
      getStatements({
        variables: {
          intervalInMonths: 6,
          personId: `${user?.personId}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statements, getStatements, user]);
  React.useEffect(() => {
    if (error) {
      showAlert({
        title: c('errors.common.wrong.title'),
        message: c('errors.common.default.message'),
        type: EAlertType.error,
      });
      return;
    }
    if (!data || R.isEmpty(data.availableStatements)) {
      return;
    } else if (data && data.availableStatements) {
      setStatements(
        data.availableStatements.filter((statement: any) => statement)
      );
    }
    return () => {
      setStatements([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setStatements, error]);
  // Handler
  const preparePrint = async (statement: Statement) => {
    setLoad(true);
    setMonth(moment(statement.date).month());
    trackAction(dataDogTags.actions.statements.printStatement, statement);
    try {
      const { data: d, errors: qe } = await client.query({
        query: GetStatementsDocument,
        variables: {
          personId: `${user?.personId}`,
          date: statement.date,
          fundsId: statement.fundId,
        },
      });

      if (qe) {
        trackError(dataDogTags.fetch.errors.downloadStatement, qe);
        const errorCode = qe[0].extensions.code;
        if (errorCode && errorCode.toString() === 'UNAUTHENTICATED') {
          showAlert({
            title: e('errors.auth.SessionExpired.title'),
            message: e('errors.auth.SessionExpired.message'),
            type: EAlertType.error,
            primaryAlertAction: () => {
              clearSession();
              closeAlert();
            },
          });
        } else {
          showAlert({
            title: c('errors.common.wrong.title'),
            message: c('errors.common.wrong.message'),
            type: EAlertType.error,
          });
        }
        return;
      } else if (d && R.isEmpty(d.statement)) {
        showAlert({
          title: c('errors.common.wrong.title'),
          message: s('statements.messages.empty'),
          type: EAlertType.error,
        });
      } else if (d && d.statements && d.statements[0]) {
        const funds: Fund[] = d.statements;
        funds.forEach(fund => {
          const operations = fund.operations
            ? fund.operations.map(opt => ({
                ...opt,
                personId: '',
                description: s(`statements.files.${opt?.description}`),
              }))
            : [];
          const doc = printStatement({ ...fund, operations }, user);
          const currentDate = moment(statement.date).format('DD/MM/YYYY');
          const fileName = `vox-capital-statement-${currentDate}`;
          saveDocument(doc, fileName);
        });
      }
    } catch (e) {
      trackError(dataDogTags.fetch.errors.downloadStatement, { e });
      showAlert({
        title: c('errors.common.wrong.title'),
        message: c('errors.common.default.message'),
        type: EAlertType.error,
      });
    }
    setLoad(false);
  };

  const getRandomImage: any = R.cond([
    [R.equals(0), R.always(Images.ImageLegacy001)],
    [R.equals(1), R.always(Images.ImageLegacy002)],
    [R.equals(2), R.always(Images.ImageLegacy003)],
    [R.equals(3), R.always(Images.ImageLegacy004)],
    [R.equals(4), R.always(Images.ImageLegacy005)],
    [R.equals(5), R.always(Images.ImageLegacy006)],
    [R.equals(6), R.always(Images.ImageLegacy007)],
    [R.equals(7), R.always(Images.ImageLegacy008)],
    [R.equals(8), R.always(Images.ImageLegacy009)],
    [R.equals(9), R.always(Images.ImageLegacy010)],
    [R.equals(10), R.always(Images.ImageLegacy011)],
    [R.T, R.always(Images.ImageLegacy010)],
  ]);
  // Component
  if (loading && networkStatus !== 3) {
    return (
      <AppExtractsWrapper data-testid='vox-loading-list'>
        <EmptyData data-testid='vox-data-loading'>
          <Loader message='Carregando' />
        </EmptyData>
      </AppExtractsWrapper>
    );
  }
  return (
    <div className='w-full flex items-center justify-center py-16' {...args}>
      {load && <Loading loadingMessage='Aguarde' />}
      <VoxLayoutContainer>
        <div className='flex flex-col gap-8'>
          <div className='w-full md:w-8/12'>
            <VoxText
              color={Colors.gray500}
              preset={TextSizes.bodyLg}
              weight={EFontWeight.medium}
            >
              Confira os extratos disponíveis na sua plataforma de investimentos
              e acompanhe de forma clara e direta o desempenho dos seus
              investimentos na VOX Capital.
            </VoxText>
          </div>
          {R.isEmpty(statements) ||
          !(statements.length > 0) ||
          (!R.isEmpty(statements) && !statements[0]) ? (
            <EmptyData data-testid='vox-empty-list'>
              <UI.VoxText preset={TextSizes.body} color={Colors.gray800}>
                {c('empty.funds.label')}
              </UI.VoxText>
              <UI.VoxText>{c('empty.funds.description')}</UI.VoxText>
              <br />
              <UI.VoxText
                underline
                color={Colors.pink800}
                preset={TextSizes.body}
                onClick={() =>
                  refetch
                    ? refetch({
                        personId: `${user?.personId}`,
                      })
                    : {}
                }
              >
                {c('empty.funds.action.label')}
              </UI.VoxText>
            </EmptyData>
          ) : (
            <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'>
              {[...statements].map((statement: Statement, k) => {
                if (statement)
                  return (
                    <div className='card' key={uniqid()} ref={cardRef}>
                      <UI.VoxCard
                        label={moment(statement.date).year().toString()}
                        title={getMonthName(`${statement.date}`)}
                        type={ECardType.compact}
                        backgroundImage={getRandomImage(k > 12 ? k - 12 : k)}
                        onClick={() =>
                          load || loading ? {} : preparePrint(statement)
                        }
                        loading={
                          (load || loading) &&
                          monthSelected === moment(statement.date).month()
                        }
                        footer={
                          <div>
                            <VoxButton
                              label='Baixar documento'
                              rightAddon={Icons.system_update_alt}
                              onClick={() =>
                                load || loading ? {} : preparePrint(statement)
                              }
                              preset={EButtonPreset.link}
                              color={Colors.darkBlue}
                            />
                          </div>
                        }
                      />
                    </div>
                  );
                return <></>;
              })}
            </div>
          )}
        </div>
      </VoxLayoutContainer>
    </div>
  );
};

export default AppExtracts;
