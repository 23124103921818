// Component generated with util/vox-create-component.js
import React from 'react';
import { AuthRecoveryProps } from './AuthRecovery.types';
import { AuthCommons } from './../../ui';
import { UI } from './../../component';
import { RecoveryFormData } from './AuthRecovery.types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { string, SchemaOf, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { authForgotPassword } from './../../service/aws/auth';
import AuthContext from './../../context/AuthContext';
import AlertContext from './../../context/AlertContext';
import { EAlertType } from '@vox-capital/vox-ui-package';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import { dataDogTags, trackView } from '../../analytics';

export const AuthRecovery: React.FC<AuthRecoveryProps> = ({ ...args }) => {
  trackView(dataDogTags.views.authRecovery, dataDogTags.views.authRecovery);
  // States
  const [isLoading, setLoading] = React.useState<boolean>(false);
  // contexts
  const { showAlert } = React.useContext(AlertContext);
  const { saveUserName } = React.useContext(AuthContext);
  // languages
  const [t] = useTranslation('auth');
  const [e] = useTranslation('errors');
  // History
  const history = useHistory();
  // Validations
  const RecoveryFormValidation: SchemaOf<RecoveryFormData> = object().shape({
    email: string().email(t('auth.signin.forms.input_user.error')).required(),
  });
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoveryFormData>({
    resolver: yupResolver(RecoveryFormValidation),
    mode: 'onSubmit',
  });
  // Handlers
  const onSubmit = handleSubmit(async (data: RecoveryFormData) => {
    setLoading(true);
    const { data: dataForgotPassword, error } = await authForgotPassword(
      data.email
    );
    setLoading(false);
    if (error) {
      showAlert({
        type: EAlertType.error,
        title: 'Aconteceu um error',
        message: error.code
          ? e(`errors.auth.${error.code}.message`)
          : t(`errors.common.default.message`),
      });
    } else {
      saveUserName({ username: data.email, ...dataForgotPassword });
      history.push('/AuthForgotPassword');
    }
  });
  return (
    <AuthCommons.AuthCommonContainer>
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText preset={TextSizes.body} weight={EFontWeight.medium}>
              {t('auth.recovery.label', {})}
            </UI.VoxText>
            <UI.VoxText
              preset={TextSizes.heading5}
              data-subtitle
              weight={EFontWeight.medium}
              color={Colors.gray500}
            >
              {t('auth.recovery.title', {})}
            </UI.VoxText>
            <div className='h-2' />
            <UI.VoxText
              preset={TextSizes.heading3}
              color={Colors.pink800}
              weight={EFontWeight.bold}
            >
              {t('auth.recovery.description')}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            <UI.VoxInput
              label=''
              placeholder={t('auth.recovery.forms.input_email.placeholder', {})}
              hasError={!!errors.email}
              errorMessage={errors.email?.message}
              isDisabled={isLoading}
              data-testid='vox-recovery-input-user'
              dark
              {...register('email')}
            />
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxButton
              data-testid='vox-recovery-event-back'
              label={t('auth.recovery.forms.button_back.label', {})}
              preset={EButtonPreset.link}
              color={Colors.light}
              onClick={() => !isLoading && history.goBack()}
              isLoading={isLoading}
              underline
              isBlock
            />
            <UI.VoxButton
              label={t('auth.recovery.forms.button_send.label', {})}
              data-testid='vox-recovery-event'
              preset={EButtonPreset.solid}
              color={Colors.light}
              onClick={onSubmit}
              isLoading={isLoading}
              isBlock
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthRecovery;
