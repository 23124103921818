import React, { useReducer, createContext } from 'react';

enum EType {
  'TOGGLE_SENSITIVE_VALUES',
}

interface ISensitiveState {
  readonly showSensitiveValues: boolean;
}

interface ISensitiveAction {
  readonly type?: keyof typeof EType;
  readonly payload?: ISensitiveState;
}

interface ISensitiveContextData {
  readonly state: ISensitiveState | undefined;
  readonly dispatch: (data: ISensitiveAction) => void;
}

const initialState: ISensitiveState = {
  showSensitiveValues: true,
};

const sensitiveReducer = (
  state: ISensitiveState = initialState,
  action: ISensitiveAction = {}
) => {
  switch (action.type) {
    case 'TOGGLE_SENSITIVE_VALUES':
      return { ...state, showSensitiveValues: !state.showSensitiveValues };
    default:
      return { ...initialState };
  }
};

export const SensitiveContext = createContext<ISensitiveContextData>(
  {} as ISensitiveContextData
);

export const SensitiveProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(sensitiveReducer, initialState);
  return (
    <SensitiveContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SensitiveContext.Provider>
  );
};

export default SensitiveContext;
