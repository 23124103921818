import { DefaultTheme } from 'styled-components';
import { voxUiTheme } from '@vox-capital/vox-ui-package';

const theme: DefaultTheme = {
  ...voxUiTheme,
};

const voxAppTheme = Object.assign({}, theme, voxUiTheme);

export { voxAppTheme };
