// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
// (38000/1024 - 100)/3 formula
export const AppInvestmentWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 25px;
`;
export const AppInvestmentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 1240px) {
    display: flex;
    flex-direction: row;
  }
`;
export const AppInvestmentOds = styled.div`
  display: flex;
  flex-direction: row;
`;
export const AppInvestmentBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray100};
  @media (min-width: 720px) {
    flex-direction: row;
    flex: 1;
  }
`;
export const AppInvestmentData = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;

  @media (min-width: 835px) {
    width: 65%;
    display: initial;
  }
`;
export const AppInvestmentDataHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding-bottom: 10px;
  padding-top: 8px;
`;
export const AppInvestmentDataBody = styled.div``;
export const AppInvestmentFinance = styled.div`
  padding: 30px;
`;
export const AppInvestmentFinanceItem = styled.div`
  margin-bottom: 15px;
`;
export const AppInvestmentImpact = styled.div`
  display: flex;
  overflow-x: auto;
  @media (max-width: 834px) {
    gap: 10px;
    [data-card] {
      min-width: 90%;
      overflow: hidden;
      border-radius: 8px;
      > div {
        overflow: hidden;
        border-radius: 8px;
      }
    }
  }
`;
export const AppInvestmentImpactItem = styled.div`
  max-width: 270px;
`;
