// Component generated with util/vox-create-component.js
import styled from 'styled-components';
import { AppHomeProps } from './AppHome.types';
import ContentLoader from 'react-content-loader';

const Loading = () => (
  <ContentLoader
    speed={2}
    style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
    viewBox='0 0 400 200'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
  >
    <rect x='0' y='0' rx='2' ry='2' width='40' height='4' />
    <rect x='0' y='6' rx='2' ry='2' width='70' height='12' />
    <rect x='0' y='33' rx='2' ry='2' width='100' height='8' />
    <rect x='0' y='48' rx='2' ry='2' width='60' height='6' />
    <rect x='50%' y='48' rx='2' ry='2' width='60' height='6' />
    <rect x='0' y='58' rx='2' ry='2' width='100%' height='500' />
  </ContentLoader>
);

/**
 * Components
 */
export const AppHomeWrapper = styled.div<AppHomeProps>`
  display: flex;
  flex-direction: column;
  .chart {
    padding: 80px 0 0px 0;
    position: relative;
    .financial-data {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .statements {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 35px;
      margin-bottom: 80px;
      .title {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 0 40px;
      }
      .items {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        justify-content: center;
        .position {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.light};
          width: 170px;
          padding: 25px 20px;
          border-radius: 4px;
          border-width: 1px;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.gray200};
          display: flex;
          flex-direction: column;
          gap: 5px;
          .fund {
            line-height: 14px;
          }
          .value {
            font-weight: bold;
            display: flex;
            gap: 4px;
            align-items: center;
            .dot {
              height: 4px;
              width: 4px;
              border-radius: 4px;
              background-color: ${({ theme }) => theme.colors.gray800};
            }
          }
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: -50px;
      bottom: 0;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.gray100};
    }
    [data-testid='vox-graph-data'] {
      .header {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  .portfolio {
    padding: 80px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: -50px;
      bottom: 0;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.gray200};
    }
    .description {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
      > .footer {
        text-align: center;
      }
    }
    .documents {
      display: flex;
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1;
    }
  }
  .impact {
    margin-top: 80px;
  }
  .loading {
    > .spinner {
      display: none;
      background-color: ${({ theme }) => theme.colors.gray100};
      flex: 1;
      padding: 20px;
      border-radius: 8px;
      justify-content: center;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .chart {
      [data-testid='vox-graph-data'] {
        .header {
          flex-direction: row;
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    .portfolio {
      flex-direction: row;
      gap: 40px;
      .description {
        text-align: left;
        &.size-25 {
          width: 25%;
        }
        &.size-50 {
          width: 50%;
        }
        &.size-75 {
          width: 75%;
        }
      }
      .list {
        > .footer {
          text-align: right;
        }
      }
      .documents {
        justify-content: flex-end;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .loading {
      > .skeleton {
        display: none;
      }
      > .spinner {
        display: flex;
      }
    }
  }
`;

export const AppHomeGraph = styled.div`
  width: calc(100% + 200px);
  transform: translateX(-100px);

  /* svg {
    width: calc(100% - 100px);
    overflow: hidden;
  
  } */
`;

export const AppHomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`;
export const AppHomeBody = styled.div`
  margin-bottom: 80px;
`;
export const AppHomeFooter = styled.div``;

export const AppHomeInvestmentResume = styled.div`
  display: flex;
  flex-direction: column;
  .money {
  }
  .statment {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .statment {
      display: initial;
    }
  }
`;

export const AppHomeInvestmentList = styled.div``;
export const AppHomeInvestmentListItem = styled.div`
  padding-top: 30px;
  padding-bottom: 25px;
`;

export const AppHomeFundsLoading = styled(Loading)``;

export const HiddeContent = styled.div<{ size: 'SMALL' | 'LARGE' }>`
  background-color: ${({ theme }) => theme.colors.gray200};
  height: ${({ size }) => (size === 'LARGE' ? 35 : 18)}px;
  width: 120px;
  border-radius: 2px;
`;

export const EmptyData = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  margin-top: 25px;
  padding-bottom: 25px;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
`;
