// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const LoaderWrapper = styled.div``;

export const LoadingAssets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;
