// Component generated with util/vox-create-component.js
import React from 'react';
import { AppNavigationWrapper } from './AppNavigation.styled';
import { AppNavigationProps } from './AppNavigation.types';
import { AppHeader } from './../AppHeader/AppHeader';

export const AppNavigation: React.FC<AppNavigationProps> = ({ ...args }) => {
  return (
    <AppNavigationWrapper {...args}>
      <AppHeader />
    </AppNavigationWrapper>
  );
};

export default AppNavigation;
