const DataDogTags = {
  views: {
    // AUTH SCREENS
    authSignIn: 'VIEW:AUTH SIGN IN',
    authRecovery: 'VIEW:AUTH RECOVERY',
    authSignUp: 'VIEW:AUTH SIGN UP',
    authNewPassword: 'VIEW:AUTH NEW PASSWORD',
    authForgotPassword: 'VIEW:AUTH FORGOT PASSWORD',
    authChooseAccount: 'VIEW:AUTH CHOOSE ACCOUNT',
    // PRIVATE SCREENS
    privateHome: 'VIEW:HOME',
    privateDocuments: 'VIEW:DOCUMENTS',
    privateStatements: 'VIEW:STATEMENTS',
    privateVideos: 'VIEW:VIDEOS',
  },
  auth: {
    navigation: {
      fluxo: 'NAVIGATION: AUTH / FLUXO VOX',
    },
  },
  actions: {
    auth: {
      fluxo: 'ACTION: AUTH / FLUXO VOX',
      site: 'ACTION: AUTH / WEB VOX',
    },
    navigation: {
      logout: 'ACTION: NAVIGATION / LOGOUT',
      contact: 'ACTION: NAVIGATION / CONTACT',
      sensitive: 'ACTION: NAVIGATION / SENSITIVE',
      fluxo: 'ACTION: NAVIGATION / FLUXO',
      profile: 'ACTION: NAVIGATION / PROFILE',
    },
    home: {
      profile: {
        requestChange: 'ACTION: HOME / PROFILE / REQUEST CHANGE',
      },
      graph: {
        years: 'ACTION: GRAPH / TOGGLE YEAR',
      },
      funds: {
        card: 'ACTION: FUNDS / CARD DETAIL',
      },
      impact: {
        card: 'ACTION: IMPACT / CARD DETAIL',
      },
      portfolio: {
        document: 'ACTION: PORTFOLIO / DOCUMENT DOWNLOAD',
        showMore: 'ACTION: PORTFOLIO / SHOW MORE DOCUMENTS',
      },
    },
    documents: {
      changeTag: 'ACTION: DOCUMENTS / CHANGE TAG',
      changeDocument: 'ACTION: DOCUMENTS / CHANGE DOCUMENT',
      printDocument: 'ACTION: DOCUMENTS / PRINT DOWNLOAD',
    },
    statements: {
      printStatement: 'ACTION: STATEMENTS / PRINT STATEMENT',
    },
  },
  session: 'SESSION: USER',
  fetch: {
    request: {
      graphql: 'FETCH: REQUEST / GRAPHQL',
      rest: 'FETCH: REQUEST / REST',
    },
    errors: {
      graphql: 'FETCH: ERRORS / GRAPHQL ERROR',
      rest: 'FETCH: ERRORS / REST ERROR',
      printfile: 'FETCH: ERRORS / PRINT FILE',
      downloadStatement: 'FETCH: ERRORS / PRINT STATEMENT',
    },
  },
};

export default DataDogTags;
